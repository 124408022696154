import { LinkedColorAlternativesListDataPageDto } from '../../dtos/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.dto';
import { LinkedColorAlternativesListDataPageModel } from '../../models/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.model';

export class LinkedColorAlternativesListDataPageDtoCreator {
  public static create(model: LinkedColorAlternativesListDataPageModel): LinkedColorAlternativesListDataPageDto {
    return {
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.page && { page: model.page }),
    };
  }
}
