<div class="recipe-details-angles">
  <app-recipe-details-measuring-angles-form
    [geometries]="geometries"
    (geometriesChanged)="updateGeometries($event)"
  ></app-recipe-details-measuring-angles-form>

  <section class="color-previews-section">
    <div class="angle-values">
      <div class="caption-1 angle-value" *ngFor="let angle of anglesIds">{{ angle }}</div>
    </div>

    <div class="color-spectrum-previews">
      <ng-container *ngFor="let angle of angles; let isLastItem = last">
        <app-recipe-details-angle-color-spectrum-preview
          class="color-spectrum-preview"
          [angle]="angle"
          [isLastItem]="isLastItem"
          *ngIf="angle?.hexColors?.length"
        ></app-recipe-details-angle-color-spectrum-preview>
      </ng-container>
    </div>
  </section>
</div>
