import { LinkedColorAlternativeListItemDto } from '../../dtos/linked-color-alternative-list-item/linked-color-alternative-list-item.dto';
import { LinkedColorAlternativeListItemModel } from '../../models/linked-color-alternative-list-item/linked-color-alternative-list-item.model';

export class LinkedColorAlternativeListItemCreator {
  public static create(dto: LinkedColorAlternativeListItemDto): LinkedColorAlternativeListItemModel {
    return {
      alternative: dto.alternative,
      colorCode: dto.color_code,
      colorId: dto.color_id,
      colorName: dto.color_name,
      formulaId: dto.formula_id,
      thumbnail: dto.thumbnail,
    };
  }
}
