import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { SeriesOptionsType } from 'highcharts';

import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsSpectralChartDataMeasurementModel } from '../../models/recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.model';
import { RecipeDetailsGeometryModel } from '../../models/recipe-details-geometry/recipe-details-geometry.model';
import { LineChartComponent } from '../../../charts/components/line-chart/line-chart.component';

@Component({
  selector: 'app-recipe-details-spectral-chart',
  templateUrl: './recipe-details-spectral-chart.component.html',
  styleUrls: ['./recipe-details-spectral-chart.component.scss'],
})
export class RecipeDetailsSpectralChartComponent implements AfterViewChecked {
  @Input() public set spectralChartData(spectralChartData: RecipeDetailsSpectralChartDataModel) {
    this.setChartData(spectralChartData);
  }

  @Output() public geometriesChanged: EventEmitter<Array<string>>;

  @ViewChild(LineChartComponent, { read: ElementRef }) public chartContainer: ElementRef<HTMLElement> | null;
  @ViewChild('chartColorSpectrum') public chartColorSpectrumElement: ElementRef<HTMLElement> | null;

  public chartOptions!: Highcharts.Options;
  public geometries: Array<RecipeDetailsGeometryModel>;
  public canShowChart: boolean;
  private spectralGradientColors: Array<[string, string]>;

  constructor() {
    this.chartOptions = this.setInitialChartOptions();
    this.geometries = [];
    this.spectralGradientColors = [];
    this.chartContainer = null;
    this.chartColorSpectrumElement = null;
    this.canShowChart = false;
    this.geometriesChanged = new EventEmitter<Array<string>>();
  }

  public ngAfterViewChecked(): void {
    this.setChartColorSpectrumElementBackground();
  }

  public updateGeometries(geometries: Array<string>): void {
    this.geometriesChanged.emit(geometries);
  }

  private getYAxisLabelsWidth(): number {
    if (!this.chartContainer) {
      return 0;
    }

    const axisLabels: HTMLElement | null = this.chartContainer.nativeElement.querySelector(
      '.highcharts-axis-labels.highcharts-yaxis-labels'
    );

    if (!axisLabels) {
      return 0;
    }

    return axisLabels.getBoundingClientRect().width;
  }

  private setChartColorSpectrumElementBackground(): void {
    if (!this.chartColorSpectrumElement?.nativeElement) {
      return;
    }

    const chartColorSpectrumElement: HTMLElement = this.chartColorSpectrumElement.nativeElement;
    const axisLabelsWidth: number = this.getYAxisLabelsWidth();
    const chartColorSpectrumElementWidth: string = `calc(100% - ${35 + axisLabelsWidth}px)`;
    const chartColorSpectrumElementLeftDistance: string = `calc(25px + ${axisLabelsWidth}px)`;

    chartColorSpectrumElement.style.backgroundImage = this.getLinearGradient();
    chartColorSpectrumElement.style.width = chartColorSpectrumElementWidth;
    chartColorSpectrumElement.style.left = chartColorSpectrumElementLeftDistance;
  }

  private setInitialChartOptions(): Highcharts.Options {
    return {
      series: [],
      xAxis: {
        categories: [],
      },
    };
  }

  private updateChartOptions(spectralChartData: RecipeDetailsSpectralChartDataModel): void {
    this.chartOptions = {
      series: this.getSeriesData(spectralChartData.measurements),
      xAxis: {
        categories: spectralChartData.wavelengths,
      },
    };
  }

  private setChartData(spectralChartData: RecipeDetailsSpectralChartDataModel): void {
    this.geometries = spectralChartData.geometries;
    this.spectralGradientColors = spectralChartData.spectralGradientColors;

    this.updateChartOptions(spectralChartData);

    this.canShowChart = !!this.chartOptions.series?.length;
  }

  private getSeriesData(measurements: Array<RecipeDetailsSpectralChartDataMeasurementModel>): Array<SeriesOptionsType> {
    return measurements.map(this.mapMeasurementDataToChartSeries.bind(this));
  }

  private mapMeasurementDataToChartSeries(measurement: RecipeDetailsSpectralChartDataMeasurementModel): SeriesOptionsType {
    return {
      color: measurement.color,
      dashStyle: measurement.dashStyle,
      data: measurement.spectralValues,
    } as SeriesOptionsType;
  }

  private getLinearGradient(): string {
    const gradientString: string = this.getGradientString();

    return `linear-gradient(90deg, ${gradientString})`;
  }

  private getGradientString(): string {
    return this.spectralGradientColors.map((colorBreakpointItem: Array<string>) => colorBreakpointItem.join(' ')).join(', ');
  }
}
