import { RecipeDetailsSpectralChartFiltersModel } from '../../models/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsSpectralChartFiltersDto } from '../../dtos/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';

export class RecipeDetailsSpectralChartFiltersDtoCreator {
  public static create(model: RecipeDetailsSpectralChartFiltersModel): RecipeDetailsSpectralChartFiltersDto {
    return {
      geometries: model.geometries,
      type: model.type,
      measurement_id: model.measurementId,
    };
  }
}
