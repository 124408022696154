import { RecipeDetailsSpectralChartDataMeasurementDto } from '../../dtos/recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.dto';
import { RecipeDetailsSpectralChartDataMeasurementModel } from '../../models/recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.model';

export class RecipeDetailsSpectralChartDataMeasurementCreator {
  public static create(dto: RecipeDetailsSpectralChartDataMeasurementDto): RecipeDetailsSpectralChartDataMeasurementModel {
    return {
      color: dto.color,
      dashStyle: dto.dash_style,
      spectralValues: dto.spectral_values,
    };
  }
}
