import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';

@Component({
  selector: 'app-recipe-details-info[details]',
  templateUrl: './recipe-details-info.component.html',
  styleUrls: ['./recipe-details-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeDetailsInfoComponent {
  @Input() public details!: RecipeFormulaDetailsModel;
  @Input() public showLinkedColorAlert: boolean;

  constructor() {
    this.showLinkedColorAlert = false;
  }
}
