<div class="recipe-details">
  <div class="alert-wrapper">
    <app-alert
      *ngIf="showLinkedColorAlert"
      class="alert"
      type="danger"
      textKey="recipes.recipeDetailsModal.detailsInfo.alert.linkedColor"
    ></app-alert>

    <app-alert class="alert" type="warning" textKey="recipes.recipeDetailsModal.detailsInfo.alert.warning"></app-alert>
  </div>

  <div class="details-table" *ngIf="details">
    <div class="details-table-column">
      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.producer' | translate }}</span>

        <span class="details-table-row-value">{{ details.brand }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.productionYear' | translate }}</span>

        <span class="details-table-row-value">{{ details.productionYear }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">
          {{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.alternativeDescription' | translate }}
        </span>

        <span class="details-table-row-value">{{ details.alternativeDescription }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">
          {{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.additionalInformation' | translate }}
        </span>

        <span class="details-table-row-value">{{ details.additionalInformation }}</span>
      </div>
    </div>

    <div class="details-table-column">
      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.colorSystem' | translate }}</span>

        <span class="details-table-row-value">{{ details.colorSystem }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.colorName' | translate }}</span>

        <span class="details-table-row-value">{{ details.producerColorName }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.colorCode' | translate }}</span>

        <span class="details-table-row-value">{{ details.producerColorCode }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.primerCode' | translate }}</span>

        <span class="primer-color-box" [style.background-color]="details.primerColor.hexColor"></span>

        <span class="details-table-row-value">{{ details.primerColor.id }}</span>
      </div>
    </div>

    <div class="details-table-column">
      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.recipeDate' | translate }}</span>

        <span class="details-table-row-value">{{ details.recipeDate }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">{{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.effect' | translate }}</span>

        <span class="details-table-row-value">{{ details.effect }}</span>
      </div>

      <div class="details-table-row">
        <span class="details-table-row-label">
          {{ 'recipes.recipeDetailsModal.detailsInfo.table.headers.effectThickness' | translate }}
        </span>

        <span class="details-table-row-value">{{ details.effectThickness }}</span>
      </div>
    </div>

    <div class="details-table-column">
      <div class="details-table-row" *ngFor="let box of details.colorBoxes">
        <span class="details-table-row-label">{{ box.label }}</span>

        <span class="details-table-row-value">{{ box.value }}</span>
      </div>
    </div>
  </div>
</div>
