<app-modal [hasContentSidePadding]="false" [hasContentVerticalPadding]="false" class="recipe-details-modal">
  <ng-container modal-content>
    <div class="recipe-details-container">
      <div class="recipe-details-header">
        <div class="recipe-details-header-down">
          <app-recipe-details-color-system-selector
            [formulaColorOptions]="formulaColorOptions"
            [isDisabled]="isColorSystemFormDisabled"
            [formControl]="colorSystemFormControl"
          ></app-recipe-details-color-system-selector>
        </div>
      </div>

      <div class="recipe-details-content">
        <ng-scrollbar class="modal-scrollbar" [autoHeightDisabled]="false">
          <ng-container
            *ngIf="!colorSystemFormControl.value && (isTabDataError$ | async) === false"
            [ngTemplateOutlet]="showWarning"
            [ngTemplateOutletContext]="{ $implicit: noColorSystemSelectedWarning }"
          ></ng-container>

          <ng-template #showWarning let-options let-showBorder="showBorder">
            <div class="no-recipe" [class.border]="showBorder">
              <app-no-results [noResultsOptions]="options"></app-no-results>
            </div>
          </ng-template>

          <ng-template #showError>
            <ng-container *ngIf="isTabDataError$ | async">
              <app-unexpected-error class="unexpected-error" (buttonClicked)="tryLoadDataAgain()"></app-unexpected-error>
            </ng-container>
          </ng-template>

          <app-loader *ngIf="colorSystemFormControl.valid && isContentLoading; else isLoaded"></app-loader>

          <ng-template #isLoaded>
            <ng-container *ngIf="selectedTab?.id === selectedTabOptions.colorCombinations">
              <app-combination-details
                [combinationDetails]="combinationDetails"
                [recipeId]="recipeDetails?.id"
                [repairId]="repairId"
                [colorSystemId]="colorSystemFormControl.value"
                *ngIf="colorCombinationDetails$ | async as combinationDetails; else showError"
              ></app-combination-details>
            </ng-container>
          </ng-template>
        </ng-scrollbar>
      </div>
    </div>
  </ng-container>

  <ng-container modal-footer>
    <div class="modal-footer-buttons">
      <app-export-combinations-to-pdf
        *ngIf="selectedTab?.id === selectedTabOptions.colorCombinations"
        [id]="$any(colorCombinationDetails$ | async)?.id"
        [isDisabled]="isContentLoading || (isTabDataError$ | async) || !$any(colorCombinationDetails$ | async)?.colors?.length"
      ></app-export-combinations-to-pdf>

      <button class="button red" (click)="close()">{{ 'recipes.recipeDetailsModal.buttons.closeModal' | translate }}</button>
    </div>
  </ng-container>
</app-modal>
