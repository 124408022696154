import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { RecipeLinkedColorsComponent } from './components/recipe-linked-colors/recipe-linked-colors.component';
import { SharedModule } from '../shared/shared.module';
import { LinkedColorListItemDetailsComponent } from './components/linked-color-list-item-details/linked-color-list-item-details.component';
import { ExportLinkedColorsToPdfComponent } from './components/export-linked-colors-to-pdf/export-linked-colors-to-pdf.component';
import { LinkedColorIconComponent } from './components/linked-color-icon/linked-color-icon.component';
import { RecipeDetailsModalService } from '../recipe-details/services/recipe-details-modal/recipe-details-modal.service';
import { ModalService } from '../modal/services/modal.service';

@NgModule({
  declarations: [
    RecipeLinkedColorsComponent,
    LinkedColorListItemDetailsComponent,
    ExportLinkedColorsToPdfComponent,
    LinkedColorIconComponent,
  ],
  imports: [CommonModule, TranslateModule, SharedModule, NgbTooltip],
  exports: [RecipeLinkedColorsComponent, ExportLinkedColorsToPdfComponent, LinkedColorIconComponent],
  providers: [RecipeDetailsModalService, ModalService],
})
export class LinkedColorsModule {}
