import { CombinationColorDto } from '../../dtos/combination-color/combination-color.dto';
import { CombinationColorModel } from '../../models/combination-color/combination-color.model';

export class CombinationColorCreator {
  public static create(dto: CombinationColorDto): CombinationColorModel {
    return {
      code: dto.code,
      id: dto.id,
      name: dto.name,
      occurrences: dto.occurrences,
      thumbnail: dto.thumbnail,
    };
  }
}
