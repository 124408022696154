<div class="spectral-chart-legend">
  <div class="spectral-chart-legend-item">
    <figure class="line dashed"></figure>

    <span class="label body-3">{{ 'mixes.spectralChart.legend.label.yourMeasurement' | translate }}</span>
  </div>

  <div class="spectral-chart-legend-item">
    <figure class="line"></figure>

    <span class="label body-3">{{ 'mixes.spectralChart.legend.label.selectedRecipe' | translate }}</span>
  </div>
</div>
