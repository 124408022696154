<div class="ingredients-layer-selector">
  <div class="recipe-price-form" [formGroup]="form">
    <app-form-control-input
      class="form-control amount"
      label="recipes.recipeDetailsModal.recipeDetailsPrices.form.amount"
      formControlName="amount"
      appFloatFormatterFromText
      [emitEvent]="false"
      [isInlineLabel]="true"
    >
      <span input-suffix>
        {{ amountSuffix }}
      </span>
    </app-form-control-input>

    <app-form-control-radio
      class="form-control"
      label="recipes.recipeDetailsModal.recipeDetailsPrices.form.unitG"
      formControlName="unit"
      [value]="recipesFormulaUnits.g"
    ></app-form-control-radio>

    <app-form-control-radio
      class="form-control"
      label="recipes.recipeDetailsModal.recipeDetailsPrices.form.unitMl"
      formControlName="unit"
      [value]="recipesFormulaUnits.ml"
    ></app-form-control-radio>
  </div>

  <app-form-control-dropdown
    class="form-control-dropdown"
    [dropdownOptions]="recipePriceOptions"
    [canClear]="false"
    [formControl]="layerSelectFormControl"
    [isInlineLabel]="true"
    label="recipes.recipeDetailsModal.recipeDetailsPrices.layersControlLabel"
  ></app-form-control-dropdown>
</div>

<div class="ingredients-list" *ngIf="layerSelectFormControl?.value">
  <app-table class="price-ingredients-table">
    <app-table-header>
      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsPrices.table.headers.thumbnail' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsPrices.table.headers.name' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsPrices.table.headers.amount' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsPrices.table.headers.sellingPrice' | translate }}
      </app-table-header-cell>
    </app-table-header>

    <ng-container ngProjectAs="app-table-row" *ngIf="layerSelectFormControl.value">
      <app-table-row
        [class.is-thinner-or-hardener]="ingredient.isThinner || ingredient.isHardener"
        *ngFor="let ingredient of layerSelectFormControl.value.ingredients"
      >
        <app-table-row-cell>
          <app-color-thumbnail [color]="ingredient.thumbnail"></app-color-thumbnail>
        </app-table-row-cell>

        <app-table-row-cell>
          <app-recipe-ingredient-name [ingredient]="ingredient"></app-recipe-ingredient-name>
        </app-table-row-cell>

        <app-table-row-cell>{{ ingredient.amount }}</app-table-row-cell>

        <app-table-row-cell>{{ ingredient.sellingPrice }}</app-table-row-cell>
      </app-table-row>
    </ng-container>
  </app-table>

  <ul class="price-summary" *ngIf="layerSelectFormControl?.value">
    <li class="price-summary-item">
      <span class="price">{{ layerSelectFormControl.value?.sellingPrice }}</span>
      <span class="description body-2">{{ 'recipes.recipeDetailsModal.recipeDetailsPrices.sellingPrice' | translate }}</span>
    </li>
    <li class="price-summary-item">
      <span class="price">
        {{ layerSelectFormControl.value?.sellingPriceWithAddons }}
      </span>
      <span class="description body-2">{{ 'recipes.recipeDetailsModal.recipeDetailsPrices.sellingPriceWithAddons' | translate }}</span>
    </li>
  </ul>
</div>
