import { RecipeDetailsModel } from '../../models/recipe-details/recipe-details.model';
import { RecipeDetailsDto } from '../../dtos/recipe-details/recipe-details.dto';

export class RecipeDetailsDtoCreator {
  public static create(model: RecipeDetailsModel): RecipeDetailsDto {
    return {
      ...(model.originalFormulaId && { original_formula_id: model.originalFormulaId }),
      ...(model.measurementId && { measurement_id: model.measurementId }),
      type: model.type,
    };
  }
}
