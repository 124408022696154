import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';

import { distinctUntilChanged } from 'rxjs';

import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';

@Component({
  selector: 'app-recipe-details-color-system-selector[formulaColorOptions]',
  templateUrl: './recipe-details-color-system-selector.component.html',
})
export class RecipeDetailsColorSystemSelectorComponent implements ControlValueAccessor {
  @Input() public formulaColorOptions: Array<DropdownOptionModel<number>>;
  @Input() public isDisabled: boolean;

  public colorSystemFormControl: FormControl;
  public isColorSystemFormDisabled: boolean;

  constructor(@Optional() ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }

    this.formulaColorOptions = [];
    this.isDisabled = false;
    this.colorSystemFormControl = new FormControl(null, { validators: Validators.required });
    this.isColorSystemFormDisabled = false;

    this.listenToColorSystemFormControlValueChanges();
  }

  public writeValue(value: number): void {
    this.colorSystemFormControl.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isColorSystemFormDisabled = isDisabled;
    isDisabled ? this.colorSystemFormControl.disable() : this.colorSystemFormControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: number) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {};

  private listenToColorSystemFormControlValueChanges(): void {
    this.colorSystemFormControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: number) => {
      this.onChange(value);
    });
  }
}
