import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RecipeDetailsDto } from '../../dtos/recipe-details/recipe-details.dto';
import { RecipeFormulaDetailsDto } from '../../../recipes/dtos/recipe-formula-details/recipe-formula-details.dto';
import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { RecipeFormulaIngredientsDataDto } from '../../../recipes/dtos/recipe-formula-ingredients-data/recipe-formula-ingredients-data.dto';
import { RecipeFormulaIngredientsDto } from '../../../recipes/dtos/recipe-formula-ingredients/recipe-formula-ingredients.dto';
import { RecipeIngredientsForMixingDataDto } from '../../dtos/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.dto';
import { RecipeLayerForMixingDto } from '../../dtos/recipe-layer-for-mixing/recipe-layer-for-mixing-model.dto';
import { QueryParamsUtil } from '../../../shared/utils/query-params/query-params.util';
import { RecipeFormulaCorrectionIngredientsDto } from '../../../recipes/dtos/recipe-formula-correction-ingredients/recipe-formula-correction-ingredients.dto';
import { RecipeDetailsSpectralChartFiltersDto } from '../../dtos/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsSpectralChartDataDto } from '../../dtos/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.dto';
import { RecipeDetailsAnglesFiltersDto } from '../../dtos/recipe-details-angles-filters/recipe-details-angles-filters.dto';
import { RecipeDetailsAnglesDataDto } from '../../dtos/recipe-details-angles-data/recipe-details-angles-data.dto';
import { RecipePricesDataDto } from '../../dtos/recipe-prices-data/recipe-prices-data.dto';
import { RecipePriceDto } from '../../dtos/recipe-price/recipe-price.dto';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsHttpService {
  constructor(private httpClient: HttpClient) {}

  public getRecipeFormulaDetails(
    colorId: number,
    formulaId: number,
    recipeDetailsDto: RecipeDetailsDto
  ): Observable<RecipeFormulaDetailsDto> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...recipeDetailsDto },
    });

    return this.httpClient
      .get<ApiResponseDto<RecipeFormulaDetailsDto>>(ApiUrlUtil.getApiUrl(`recipe/recipes/${colorId}/${formulaId}`), { params })
      .pipe(map((response: ApiResponseDto<RecipeFormulaDetailsDto>) => response.data));
  }

  public getRecipeIngredients(
    formulaId: number,
    recipeFormulaIngredientsDataDto: RecipeFormulaIngredientsDataDto
  ): Observable<Array<RecipeFormulaIngredientsDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...recipeFormulaIngredientsDataDto },
    });

    return this.httpClient
      .get<ApiResponseDto<Array<RecipeFormulaIngredientsDto>>>(ApiUrlUtil.getApiUrl(`recipe/formulas/${formulaId}/ingredients`), { params })
      .pipe(map((response: ApiResponseDto<Array<RecipeFormulaIngredientsDto>>) => response.data));
  }

  public getRecipeIngredientsForMixing(
    formulaId: number,
    recipeIngredientsForMixingDataDto: RecipeIngredientsForMixingDataDto
  ): Observable<Array<RecipeLayerForMixingDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<RecipeIngredientsForMixingDataDto>(recipeIngredientsForMixingDataDto);

    return this.httpClient
      .get<ApiResponseDto<Array<RecipeLayerForMixingDto>>>(ApiUrlUtil.getApiUrl(`recipe/formulas/${formulaId}/mixture-ingredients`), {
        params,
      })
      .pipe(map((response: ApiResponseDto<Array<RecipeLayerForMixingDto>>) => response.data));
  }

  public getRecipeCorrectionIngredients(measurementId: string, recipeSignature: string): Observable<Array<RecipeFormulaIngredientsDto>> {
    return this.httpClient
      .get<ApiResponseDto<RecipeFormulaCorrectionIngredientsDto>>(
        ApiUrlUtil.getApiUrl(`recipe/recipes/${recipeSignature}/correction/${measurementId}/ingredients`)
      )
      .pipe(map((response: ApiResponseDto<RecipeFormulaCorrectionIngredientsDto>) => response.data.elements));
  }

  public getSpectralChartData(
    colorId: string,
    formulaId: string,
    spectralChartFiltersDto: RecipeDetailsSpectralChartFiltersDto
  ): Observable<RecipeDetailsSpectralChartDataDto> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<RecipeDetailsSpectralChartFiltersDto>(spectralChartFiltersDto);

    return this.httpClient
      .get<ApiResponseDto<RecipeDetailsSpectralChartDataDto>>(
        ApiUrlUtil.getApiUrl(`recipe/formulas/${colorId}/${formulaId}/spectral-chart`),
        { params }
      )
      .pipe(map((response: ApiResponseDto<RecipeDetailsSpectralChartDataDto>) => response.data));
  }

  public getAnglesData(
    colorId: string,
    formulaId: string,
    filtersDto: RecipeDetailsAnglesFiltersDto
  ): Observable<RecipeDetailsAnglesDataDto> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<RecipeDetailsAnglesFiltersDto>(filtersDto);

    return this.httpClient
      .get<ApiResponseDto<RecipeDetailsAnglesDataDto>>(ApiUrlUtil.getApiUrl(`recipe/formulas/${colorId}/${formulaId}/angles`), { params })
      .pipe(map((response: ApiResponseDto<RecipeDetailsAnglesDataDto>) => response.data));
  }

  public getRecipePrices(formulaId: number, recipePricesData: RecipePricesDataDto): Observable<Array<RecipePriceDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...recipePricesData },
    });

    return this.httpClient
      .get<ApiResponseDto<Array<RecipePriceDto>>>(ApiUrlUtil.getApiUrl(`recipe/formulas/${formulaId}/prices`), { params })
      .pipe(map((response: ApiResponseDto<Array<RecipePriceDto>>) => response.data));
  }
}
