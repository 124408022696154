<div class="ingredients-layer-selector">
  <label class="label subtitle-2">{{ 'recipes.recipeDetailsModal.recipeDetailsIngredients.title' | translate }}</label>

  <app-form-control-dropdown
    class="form-control-dropdown"
    [dropdownOptions]="ingredientsOptions"
    [canClear]="false"
    [formControl]="layerSelect"
    [isInlineLabel]="true"
    label="recipes.recipeDetailsModal.recipeDetailsIngredients.layersControlLabel"
  ></app-form-control-dropdown>
</div>

<div class="ingredients-list">
  <app-table class="ingredients-table" [class.has-correction]="_isCorrection">
    <app-table-header>
      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.thumbnail' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.name' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ ingredientsAmountHeading | translate }}
      </app-table-header-cell>

      <app-table-header-cell *ngIf="_isCorrection" class="table-header-cell">
        {{ 'recipes.recipeDetailsModal.recipeDetailsIngredients.tabs.correctionAmount' | translate }}
      </app-table-header-cell>
    </app-table-header>

    <ng-container ngProjectAs="app-table-row" *ngIf="layerSelect.value">
      <app-table-row
        [class.is-thinner-or-hardener]="ingredient.isThinner || ingredient.isHardener"
        *ngFor="let ingredient of layerSelect.value.ingredients"
      >
        <app-table-row-cell>
          <app-color-thumbnail [color]="ingredient.thumbnail"></app-color-thumbnail>
        </app-table-row-cell>

        <app-table-row-cell>
          <app-recipe-ingredient-name [ingredient]="ingredient"></app-recipe-ingredient-name>
        </app-table-row-cell>

        <app-table-row-cell>{{ ingredient.amount }}</app-table-row-cell>

        <app-table-row-cell *ngIf="_isCorrection">
          {{ ingredient.correctionAmount }}
        </app-table-row-cell>
      </app-table-row>
    </ng-container>
  </app-table>
</div>
