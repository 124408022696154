import { RecipeDetailsAnglesFiltersModel } from '../../models/recipe-details-angles-filters/recipe-details-angles-filters.model';
import { RecipeDetailsAnglesFiltersDto } from '../../dtos/recipe-details-angles-filters/recipe-details-angles-filters.dto';

export class RecipeDetailsAnglesFiltersDtoCreator {
  public static create(model: RecipeDetailsAnglesFiltersModel): RecipeDetailsAnglesFiltersDto {
    return {
      geometries: model.geometries,
      measurement_id: model.measurementId,
      include_correction: Number(model.includesCorrection),
    };
  }
}
