import { Component, OnInit } from '@angular/core';

import { filter } from 'rxjs';

import { RecipeDetailsBaseModalComponent } from '../recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';

@Component({
  selector: 'app-recipe-details-linked-colors-modal',
  templateUrl: './recipe-details-linked-colors-modal.component.html',
  styleUrl: './recipe-details-linked-colors-modal.component.scss',
})
export class RecipeDetailsLinkedColorsModalComponent extends RecipeDetailsBaseModalComponent implements OnInit {
  public ngOnInit(): void {
    this.listenToColorSystemFormChanges();
    this.listenToRecipeTypeFormChanges();
    this.loadTabData(this.selectedTab as RecipeDetailsTabModel);
  }

  public override tryLoadDataAgain(): void {
    super.loadTabData(this.selectedTab as RecipeDetailsTabModel);
  }

  private listenToRecipeTypeFormChanges(): void {
    this.subscription = this.recipeTypeForm.valueChanges.pipe(filter(() => !!this.recipeDetails && this.colorSystemId !== null)).subscribe({
      next: () => {
        this.loadTabData(this.selectedTab as RecipeDetailsTabModel);
      },
    });
  }
}
