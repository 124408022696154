import { Injectable, Type, Injector } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, tap, map } from 'rxjs';

import { ModalService } from '../../../modal/services/modal.service';
import { RecipeDetailsBaseModalComponent } from '../../components/recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeListItemXriteCorrectionModel } from '../../../recipes/models/recipe-list-item-xrite-correction/recipe-list-item-xrite-correction.model';
import { RecipeDetailsColorCombinationsModalComponent } from '../../components/recipe-details-color-combinations-modal/recipe-details-color-combinations-modal.component';
import { RecipeDetailsLinkedColorsModalComponent } from '../../components/recipe-details-linked-colors-modal/recipe-details-linked-colors-modal.component';
import { RecipeDetailsModalComponent } from '../../components/recipe-details-modal/recipe-details-modal.component';
import { ModalSizeEnum } from '../../../modal/enums/modal-size.enum';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeDetailsService } from '../recipe-details/recipe-details.service';
import { RecipeDetailsStateService } from '../recipe-details-state/recipe-details-state.service';
import { RecipeDetailsTabsService } from '../recipe-details-tabs/recipe-details-tabs.service';

@Injectable()
export class RecipeDetailsModalService {
  private modalStack: Array<NgbModalRef> = [];

  constructor(private modalService: ModalService, private ngbModal: NgbModal, private injector: Injector) {}

  public openRecipeDetailsModal(
    recipe: RecipeDetailsModalParamsModel,
    colorSystemId: number | null,
    repairId?: string,
    measurementId?: string,
    isCorrectionStatusShown?: boolean
  ): Observable<void> {
    const currentModal: NgbModalRef = this.modalStack[this.modalStack.length - 1];

    if (currentModal) {
      this.modalService.hideModal();
    }

    const modalComponent: Type<RecipeDetailsBaseModalComponent> = this.getRecipeDetailsModalComponent(recipe);
    const modalRef: NgbModalRef = this.ngbModal.open(modalComponent, {
      animation: false,
      centered: true,
      backdrop: 'static',
      windowClass: `modal ${ModalSizeEnum.large}`,
      keyboard: true,
      injector: Injector.create({
        providers: [
          { provide: RecipeDetailsService, useClass: RecipeDetailsService },
          { provide: RecipeDetailsStateService, useClass: RecipeDetailsStateService },
          { provide: RecipeDetailsTabsService, useClass: RecipeDetailsTabsService },
        ],
        parent: this.injector,
      }),
    });

    if (!modalRef?.componentInstance) {
      return from([]);
    }

    const instance: RecipeDetailsBaseModalComponent = modalRef.componentInstance;

    instance.recipeDetailsStateService.resetState();
    instance.recipeDetailsStateService.updateRecipeState({
      recipe,
      colorId: recipe.colorId,
      formulaId: recipe.formulas[0].id,
    });

    instance.colorSystemId = Number(colorSystemId);
    instance.repairId = repairId;
    instance.measurementId = measurementId;
    instance.selectedRecipe = recipe;
    instance.isCorrectionOptionEnabled = this.getIsCorrectionOptionEnabled(recipe, !!isCorrectionStatusShown);
    instance.isCorrectionStatusShown = !!isCorrectionStatusShown;
    instance.colorId = recipe.colorId;
    instance.formulaId = recipe.formulas[0].id;

    this.modalStack.push(modalRef);

    return from(modalRef.result).pipe(
      tap(() => {
        this.modalStack.pop();

        const previousModal: NgbModalRef = this.modalStack[this.modalStack.length - 1];

        if (!previousModal) {
          return;
        }

        this.modalService.showModal();
      }),
      map(() => void 0)
    );
  }

  private getRecipeDetailsModalComponent(recipe: RecipeDetailsModalParamsModel): Type<RecipeDetailsBaseModalComponent> {
    if (recipe.combination?.isCombination) {
      return RecipeDetailsColorCombinationsModalComponent;
    }

    return recipe.linkedColor?.isLinkedColor ? RecipeDetailsLinkedColorsModalComponent : RecipeDetailsModalComponent;
  }

  private getIsCorrectionOptionEnabled(recipe: RecipeDetailsModalParamsModel, canShowCorrectionStatus: boolean): boolean {
    if (!canShowCorrectionStatus) {
      return false;
    }

    const xriteCorrection: RecipeListItemXriteCorrectionModel | null | undefined = recipe?.xriteCorrection;

    return !xriteCorrection?.state.isError && !xriteCorrection?.state.isLoading && !!xriteCorrection?.data?.correctionPerformed;
  }
}
