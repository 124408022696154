import { RecipeDetailsAnglesDataDto } from '../../dtos/recipe-details-angles-data/recipe-details-angles-data.dto';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipeDetailsAngleCreator } from '../recipe-details-angle/recipe-details-angle.creator';

export class RecipeDetailsAnglesDataCreator {
  public static create(dto: RecipeDetailsAnglesDataDto): RecipeDetailsAnglesDataModel {
    return {
      geometries: dto.geometries,
      angles: dto.angles.map(RecipeDetailsAngleCreator.create.bind(this)),
    };
  }
}
