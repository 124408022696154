import { CombinationColorDto } from '../../dtos/combination-color/combination-color.dto';
import { CombinationDetailsDto } from '../../dtos/combination-details/combination-details.dto';
import { CombinationColorModel } from '../../models/combination-color/combination-color.model';
import { CombinationDetailsModel } from '../../models/combination-details/combination-details.model';
import { CombinationColorCreator } from '../combination-color/combination-color.creator';

export class CombinationDetailsCreator {
  public static create(dto: CombinationDetailsDto): CombinationDetailsModel {
    return {
      brand: dto.brand,
      code: dto.code,
      name: dto.name,
      colors: CombinationDetailsCreator.createColors(dto.colors),
      id: dto.id,
    };
  }
  private static createColors(dtos: Array<CombinationColorDto>): Array<CombinationColorModel> {
    return dtos.map((dto: CombinationColorDto) => CombinationColorCreator.create(dto));
  }
}
