import { RecipeLayerIngredientForMixingDto } from '../../dtos/recipe-layer-ingredient-for-mixing/recipe-layer-ingredient-for-mixing.dto';
import { RecipeLayerIngredientForMixingModel } from '../../models/recipe-layer-ingredient-for-mixing/recipe-layer-ingredient-for-mixing.model';

export class RecipeLayerIngredientForMixingCreator {
  public static create(dto: RecipeLayerIngredientForMixingDto): RecipeLayerIngredientForMixingModel {
    return {
      alternatives: dto.alternatives,
      amount: dto.amount,
      amountRaw: {
        value: dto.amount_raw.value,
        ascending: dto.amount_raw.ascending,
        min: dto.amount_raw.min || dto.amount_raw.value,
        max: dto.amount_raw.max || dto.amount_raw.value,
      },
      isComponentWithdraw: dto.component_withdraw,
      isHardener: dto.hardener,
      id: dto.id,
      name: dto.name,
      isPowder: dto.powder,
      isSpecialIngredient: dto.special_ingredient,
      isThinner: dto.thinner,
      thumbnail: dto.thumbnail,
    };
  }
}
