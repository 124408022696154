<app-modal>
  <span modal-title>{{ 'recipes.checkRecipeTypeModal.title' | translate }}</span>

  <div modal-content>{{ 'recipes.checkRecipeTypeModal.text' | translate }}</div>

  <ng-container modal-footer>
    <button class="button black-outline" (click)="cancel()">
      {{ 'recipes.checkRecipeTypeModal.button.cancel' | translate }}
    </button>

    <button class="button red-outline" (click)="selectOriginal()">
      {{ 'recipes.checkRecipeTypeModal.button.original' | translate }}
    </button>

    <button class="button red" (click)="selectCorrection()">
      {{ 'recipes.checkRecipeTypeModal.button.correction' | translate }}
    </button>
  </ng-container>
</app-modal>
