import { RecipeFormulaIngredientsDto } from '../../../recipes/dtos/recipe-formula-ingredients/recipe-formula-ingredients.dto';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeIngredientsDto } from '../../dtos/recipe-ingredients/recipe-ingredients.dto';

export class RecipeFormulaIngredientsCreator {
  public static create(dto: RecipeFormulaIngredientsDto): RecipeFormulaIngredientsModel {
    return {
      hasHardener: dto.has_hardener,
      hasThinner: dto.has_thinner,
      layerNumber: dto.layer_number,
      ingredients: dto.ingredients.map((ingredient: RecipeIngredientsDto) => {
        return {
          amount: ingredient.amount,
          amountAscending: ingredient.amount_ascending,
          isComponentWithdraw: ingredient.component_withdraw,
          isHardener: ingredient.hardener,
          id: ingredient.id,
          name: ingredient.name,
          isPowder: ingredient.powder,
          isSpecialIngredient: ingredient.special_ingredient,
          isThinner: ingredient.thinner,
          thumbnail: ingredient.thumbnail,
          ...(ingredient.correction_amount && { correctionAmount: ingredient.correction_amount }),
        };
      }),
    };
  }
}
