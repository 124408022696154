import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ListModel } from '../../../shared/models/list/list.model';
import { LinkedColorAlternativeListItemModel } from '../../models/linked-color-alternative-list-item/linked-color-alternative-list-item.model';
import { LinkedColorsService } from '../../services/linked-colors/linked-colors.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { LinkedColorAlternativesListDataPageModel } from '../../models/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.model';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { RecipeDetailsService } from '../../../recipe-details/services/recipe-details/recipe-details.service';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';

@Component({
  selector: 'app-linked-color-list-item-details[id][colorId]',
  templateUrl: './linked-color-list-item-details.component.html',
  styleUrls: ['./linked-color-list-item-details.component.scss'],
})
export class LinkedColorListItemDetailsComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public id!: string;
  @Input() public colorId!: number;
  @Input() public recipeId?: string;
  @Input() public measurementId?: string;
  @Input() public repairId?: string;

  @Output() public openDetailsModalClicked: EventEmitter<LinkedColorAlternativeListItemModel> =
    new EventEmitter<LinkedColorAlternativeListItemModel>();

  public linkedColorAlternativesList: Array<LinkedColorAlternativeListItemModel>;
  public isLoading: boolean;

  constructor(
    private linkedColorsService: LinkedColorsService,
    private recipeDetailsService: RecipeDetailsService,
    private activeModal: NgbActiveModal,
    private router: Router
  ) {
    super();

    this.linkedColorAlternativesList = [];
    this.isLoading = false;
    this.openDetailsModalClicked = new EventEmitter<LinkedColorAlternativeListItemModel>();
  }

  public ngOnInit(): void {
    this.initLinkedColorAlternativesList();
  }

  public openDetailsModal(alternative: LinkedColorAlternativeListItemModel): void {
    this.openDetailsModalClicked.emit(alternative);
  }

  public goToPrepareMixturePage(alternative: LinkedColorAlternativeListItemModel): void {
    this.navigateToPrepareMixturePage(alternative);
  }

  private navigateToPrepareMixturePage(alternative: LinkedColorAlternativeListItemModel): void {
    if (!this.recipeId) {
      return;
    }

    this.subscription = this.recipeDetailsService
      .getRecipeDetailsToPrepareMixture(
        RecipeTypeEnum.novolRecipe,
        alternative.colorId,
        alternative.formulaId,
        this.recipeId,
        this.measurementId,
        this.repairId
      )
      .subscribe((prepareMixtureState: PrepareMixtureStateModel) => {
        const locationState: CanDeactivateGuardModel<PrepareMixtureStateModel> = {
          canDeactivate: true,
          data: prepareMixtureState,
        };

        this.router.navigate(['/mixes/prepare-mixture'], { state: LocationGetStateUtil.createState(locationState) }).then(() => {
          this.activeModal.dismiss();
        });
      });
  }

  private initLinkedColorAlternativesList(): void {
    const linkedColorAlternativesListDataPage: LinkedColorAlternativesListDataPageModel = { page: 1 };

    this.setIsLoading(true);

    this.subscription = this.linkedColorsService
      .getLinkedColorAlternativesList(this.id, this.colorId, linkedColorAlternativesListDataPage)
      .pipe(finalize(() => this.setIsLoading(false)))
      .subscribe({
        next: (list: ListModel<LinkedColorAlternativeListItemModel>) => {
          this.linkedColorAlternativesList = list.items;
        },
      });
  }

  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
