import { LinkedColorDto } from '../../dtos/linked-color/linked-color.dto';
import { LinkedColorsDetailsDto } from '../../dtos/linked-colors-details/linked-colors-details.dto';
import { LinkedColorModel } from '../../models/linked-color/linked-color.model';
import { LinkedColorsDetailsModel } from '../../models/linked-colors-details/linked-colors-details.model';
import { LinkedColorCreator } from '../linked-color/linked-color.creator';

export class LinkedColorsDetailsCreator {
  public static create(dto: LinkedColorsDetailsDto): LinkedColorsDetailsModel {
    return {
      brand: dto.brand,
      colors: LinkedColorsDetailsCreator.createColors(dto.colors),
      id: dto.id,
    };
  }

  private static createColors(dtos: Array<LinkedColorDto>): Array<LinkedColorModel> {
    return dtos.map((dto: LinkedColorDto) => LinkedColorCreator.create(dto));
  }
}
