import { RecipeDetailsAngleDto } from '../../dtos/recipe-details-angle/recipe-details-angle.dto';
import { RecipeDetailsAngleModel } from '../../models/recipe-details-angles/recipe-details-angle.model';
import { RecipeDetailsAngleHexColorModel } from '../../models/recipe-details-angle-hex-color/recipe-details-angle-hex-color.model';
import { RecipeDetailsAngleHexColorDto } from '../../dtos/recipe-details-angle-hex-color/recipe-details-angle-hex-color.dto';
import { RecipeDetailsAngleHexColorCreator } from '../recipe-details-angle-hex-color/recipe-details-angle-hex-color.creator';

export class RecipeDetailsAngleCreator {
  public static create(dto: RecipeDetailsAngleDto): RecipeDetailsAngleModel {
    return {
      type: dto.type,
      name: dto.name,
      hexColors: RecipeDetailsAngleCreator.getHexColors(dto.hex_colors),
    };
  }

  private static getHexColors(hexColorsDto: Array<RecipeDetailsAngleHexColorDto>): Array<RecipeDetailsAngleHexColorModel> {
    return hexColorsDto.map(RecipeDetailsAngleHexColorCreator.create.bind(this));
  }
}
