import { RecipeDetailsGeometryDto } from '../../dtos/recipe-details-geometry/recipe-details-geometry.dto';
import { RecipeDetailsGeometryModel } from '../../models/recipe-details-geometry/recipe-details-geometry.model';

export class RecipeDetailsGeometryCreator {
  public static create(dto: RecipeDetailsGeometryDto): RecipeDetailsGeometryModel {
    return {
      key: dto.key,
      label: dto.label,
      checked: dto.checked,
    };
  }
}
