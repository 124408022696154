<div class="recipe-details-spectral-chart">
  <app-recipe-details-measuring-angles-form
    [geometries]="geometries"
    (geometriesChanged)="updateGeometries($event)"
  ></app-recipe-details-measuring-angles-form>

  <div class="recipe-details-spectral-chart-container">
    <app-line-chart [chartOptions]="chartOptions"></app-line-chart>

    <div class="recipe-details-spectral-chart-color-spectrum" #chartColorSpectrum></div>
  </div>

  <app-recipe-details-spectral-chart-legend></app-recipe-details-spectral-chart-legend>
</div>
