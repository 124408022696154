import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipesFormulaUnitsTypeModel } from '../../models/recipes-formula-units-type/recipes-formula-units-type.model';
import { RecipesFormulaUnitsEnum } from '../../../recipes/enums/recipes-formula-units/recipes-formula-units.enum';
import { FormControlsOf } from '../../../forms/models/form-controls-of/form-controls-of.model';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { distinctUntilChangedJSON } from '../../../shared/custom-operators/distinct-until-changed-json/distinct-until-changed-json.operator';
import { ToastsService } from '../../../toasts/services/toasts/toasts.service';
import { FormControlInputComponent } from '../../../forms/components/form-control-input/form-control-input.component';

@Component({
  selector: 'app-recipe-details-prices[recipePriceLists]',
  templateUrl: './recipe-details-prices.component.html',
  styleUrls: ['./recipe-details-prices.component.scss'],
})
export class RecipeDetailsPricesComponent extends SubscriptionStoreComponent implements OnInit, AfterViewInit {
  @Input() public set recipePriceLists(recipePriceLists: Array<RecipePriceModel>) {
    this.buildRecipePriceLists(recipePriceLists);
  }
  @Input() public set recipePriceForm(recipePriceForm: RecipePriceFormModel) {
    this.setFormValues(recipePriceForm);
  }

  @Output() public recipePriceFormValueChanged: EventEmitter<RecipePriceFormModel>;

  @ViewChild(FormControlInputComponent) public amountFormControlInput!: FormControlInputComponent;

  public recipePriceOptions: Array<DropdownOptionModel<RecipePriceModel>>;
  public layerSelectFormControl: FormControl<RecipePriceModel | null>;
  public form: FormGroup<FormControlsOf<RecipePriceFormModel>>;
  public amountSuffix: RecipesFormulaUnitsTypeModel;
  public recipesFormulaUnits: typeof RecipesFormulaUnitsEnum;

  private recipePriceFormChangeTimeout: number;
  private previousRecipePriceFormValue: RecipePriceFormModel;

  constructor(private translateService: TranslateService, private toastsService: ToastsService) {
    super();

    this.layerSelectFormControl = new FormControl(null);
    this.recipePriceOptions = [];
    this.amountSuffix = RecipesFormulaUnitsEnum.ml;
    this.recipesFormulaUnits = RecipesFormulaUnitsEnum;
    this.form = this.getForm();
    this.previousRecipePriceFormValue = this.form.getRawValue();
    this.recipePriceFormValueChanged = new EventEmitter<RecipePriceFormModel>();
    this.recipePriceFormChangeTimeout = 1000;
  }

  public ngOnInit(): void {
    this.initFormOnChangesListener();
  }

  public ngAfterViewInit(): void {
    this.amountFormControlInput.focus();
  }

  private get isFormValueChanged(): boolean {
    const form: RecipePriceFormModel = this.form.getRawValue();

    if (+form.amount !== +this.previousRecipePriceFormValue.amount) {
      return true;
    }

    return form.unit !== this.previousRecipePriceFormValue.unit;
  }

  private buildRecipePriceLists(recipePriceLists: Array<RecipePriceModel>): void {
    this.recipePriceOptions = recipePriceLists.map((recipePrice: RecipePriceModel) => ({
      label: this.translateService.instant('recipes.recipeDetailsModal.recipeDetailsPrices.layersLabel', {
        number: recipePrice.layerNumber,
      }),
      value: recipePrice,
    }));

    if (this.recipePriceOptions.length) {
      this.layerSelectFormControl.setValue(this.recipePriceOptions[0].value);
    }
  }

  private getForm(): FormGroup<FormControlsOf<RecipePriceFormModel>> {
    return new FormGroup<FormControlsOf<RecipePriceFormModel>>({
      amount: new FormControl('100', { nonNullable: true }),
      unit: new FormControl(RecipesFormulaUnitsEnum.ml, { nonNullable: true }),
    });
  }

  private initFormOnChangesListener(): void {
    this.subscription = this.form.valueChanges
      .pipe(
        tap(() => {
          this.initAmountSuffix();
        }),
        map(() => this.form.getRawValue()),
        distinctUntilChangedJSON<RecipePriceFormModel>(),
        debounceTime(this.recipePriceFormChangeTimeout),
        filter(() => this.isAmountGreaterThanZero())
      )
      .subscribe(() => {
        this.emitRecipePriceFormValueChange();
      });
  }

  private initAmountSuffix(): void {
    const recipePriceForm: RecipePriceFormModel = this.form.getRawValue();

    this.amountSuffix = recipePriceForm.unit;
  }

  private isAmountGreaterThanZero(): boolean {
    const amount: number = +this.form.getRawValue().amount;

    if (amount > 0) {
      return true;
    }

    this.toastsService.showError('recipes.recipeDetailsModal.recipeDetailsPrices.toasts.amountError');
    return false;
  }

  private emitRecipePriceFormValueChange(): void {
    const recipePriceFormValue: RecipePriceFormModel = this.form.getRawValue();

    if (!this.isFormValueChanged) {
      return;
    }

    this.recipePriceFormValueChanged.emit(recipePriceFormValue);
    this.updatePreviousRecipePriceFormValue();
  }

  private updatePreviousRecipePriceFormValue(): void {
    this.previousRecipePriceFormValue = this.form.getRawValue();
  }

  private setFormValues(recipePriceForm: RecipePriceFormModel): void {
    this.form.patchValue(recipePriceForm);
    this.initAmountSuffix();
    this.updatePreviousRecipePriceFormValue();
  }
}
