import { Component, OnDestroy, OnInit } from '@angular/core';

import { RecipeDetailsBaseModalComponent } from '../recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';

@Component({
  selector: 'app-recipe-details-color-combinations-modal',
  templateUrl: './recipe-details-color-combinations-modal.component.html',
  styleUrl: './recipe-details-color-combinations-modal.component.scss',
})
export class RecipeDetailsColorCombinationsModalComponent extends RecipeDetailsBaseModalComponent implements OnInit, OnDestroy {
  protected override showTabs: boolean = false;

  public ngOnInit(): void {
    this.listenToColorSystemFormChanges();
    this.setActiveTab(RecipeDetailsTabsEnum.colorCombinations);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.setActiveTab(RecipeDetailsTabsEnum.info);
  }

  private setActiveTab(tabId: RecipeDetailsTabsEnum): void {
    this.recipeDetailsTabsService.setActiveTab(tabId);
  }
}
