import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { RecipeDetailsHttpService } from '../recipe-details-http/recipe-details-http.service';
import { RecipeDetailsModel } from '../../models/recipe-details/recipe-details.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeFormulaIngredientsDataModel } from '../../models/recipe-formula-ingredients-data/recipe-formula-ingredients-data.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsSpectralChartFiltersModel } from '../../models/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipeDetailsAnglesFiltersModel } from '../../models/recipe-details-angles-filters/recipe-details-angles-filters.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipePricesDataModel } from '../../models/recipe-prices-data/recipe-prices-data.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeDetailsDto } from '../../dtos/recipe-details/recipe-details.dto';
import { RecipeDetailsDtoCreator } from '../../creators/recipe-details-dto/recipe-details-dto.creator';
import { RecipeFormulaDetailsCreator } from '../../creators/recipe-formula-details/recipe-formula-details.creator';
import { RecipeDetailsSpectralChartFiltersDto } from '../../dtos/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsSpectralChartFiltersDtoCreator } from '../../creators/recipe-details-spectral-chart-filters-dto/recipe-details-spectral-chart-filters-dto.creator';
import { RecipeDetailsSpectralChartDataCreator } from '../../creators/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.creator';
import { RecipeDetailsAnglesFiltersDto } from '../../dtos/recipe-details-angles-filters/recipe-details-angles-filters.dto';
import { RecipeDetailsAnglesFiltersDtoCreator } from '../../creators/recipe-details-angles-filters-dto/recipe-details-angles-filters-dto.creator';
import { RecipeDetailsAnglesDataCreator } from '../../creators/recipe-details-angles-data/recipe-details-angles-data.creator';
import { RecipePricesDataDto } from '../../dtos/recipe-prices-data/recipe-prices-data.dto';
import { RecipePricesDataDtoCreator } from '../../creators/recipe-prices-data-dto/recipe-prices-data-dto.creator';
import { RecipePriceDto } from '../../dtos/recipe-price/recipe-price.dto';
import { RecipePriceCreator } from '../../creators/recipe-price/recipe-price.creator';
import { RecipeFormulaIngredientsDataDto } from '../../../recipes/dtos/recipe-formula-ingredients-data/recipe-formula-ingredients-data.dto';
import { RecipeFormulaIngredientsDataDtoCreator } from '../../creators/recipe-formula-ingredients-data-dto/recipe-formula-ingredients-data-dto.creator';
import { RecipeFormulaIngredientsDto } from '../../../recipes/dtos/recipe-formula-ingredients/recipe-formula-ingredients.dto';
import { RecipeFormulaIngredientsCreator } from '../../creators/recipe-formula-ingredients/recipe-formula-ingredients.creator';
import { RecipeIngredientsForMixingDataModel } from '../../../recipes/models/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.model';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { RecipeIngredientsForMixingDataDto } from '../../dtos/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.dto';
import { RecipeIngredientsForMixingDataDtoCreator } from '../../creators/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data-dto.creator';
import { RecipeLayerForMixingDto } from '../../dtos/recipe-layer-for-mixing/recipe-layer-for-mixing-model.dto';
import { RecipeLayerForMixingCreator } from '../../creators/recipe-layer-for-mixing/recipe-layer-for-mixing.creator';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { SettingsOptionCollectionListItemModel } from '../../../settings/models/settings-option-collection-list-item/settings-option-collection-list-item.model';
import { RecipeFormulaBasicModel } from '../../models/recipe-formula-basic/recipe-formula-basic.model';

@Injectable({
  providedIn: 'root',
})
export class RecipeFormulaDataService {
  constructor(private recipeDetailsHttpService: RecipeDetailsHttpService, private currentSettingsService: CurrentSettingsService) {}

  public updateRecipeFormulaDetails(
    formulaId: number,
    colorId: number,
    recipeType: RecipeTypeModel,
    measurementId?: string
  ): Observable<RecipeFormulaDetailsModel> {
    const recipeDetails: RecipeDetailsModel = {
      type: recipeType,
      measurementId,
    };

    return this.recipeDetailsHttpService
      .getRecipeFormulaDetails(colorId, formulaId, recipeDetails)
      .pipe(map(RecipeFormulaDetailsCreator.create.bind(this)));
  }

  public getRecipeFormulaDetails(
    colorId: number,
    formulaId: number,
    recipeDetails: RecipeDetailsModel | null
  ): Observable<RecipeFormulaDetailsModel> {
    if (!recipeDetails || !colorId || !formulaId) {
      return EMPTY;
    }

    const recipeDetailsDto: RecipeDetailsDto = RecipeDetailsDtoCreator.create(recipeDetails);

    return this.recipeDetailsHttpService
      .getRecipeFormulaDetails(colorId, formulaId, recipeDetailsDto)
      .pipe(map(RecipeFormulaDetailsCreator.create.bind(this)));
  }

  public updateRecipeFormulaSpectralChart(
    formulaId: number,
    colorId: number,
    geometries: Array<string>,
    recipeType: RecipeTypeModel,
    measurementId?: string
  ): Observable<RecipeDetailsSpectralChartDataModel> {
    const spectralChartFilters: RecipeDetailsSpectralChartFiltersModel = {
      type: recipeType,
      measurementId: measurementId || '',
      geometries,
    };

    const spectralChartFiltersDto: RecipeDetailsSpectralChartFiltersDto =
      RecipeDetailsSpectralChartFiltersDtoCreator.create(spectralChartFilters);

    return this.recipeDetailsHttpService
      .getSpectralChartData(colorId.toString(), formulaId.toString(), spectralChartFiltersDto)
      .pipe(map(RecipeDetailsSpectralChartDataCreator.create.bind(this)));
  }

  public getFormulasColorSystems(formulas: Array<RecipeFormulaBasicModel>): Observable<Array<DropdownOptionModel<number>>> {
    return this.getColorSystemOptions().pipe(
      map((colorSystemOptions: Array<DropdownOptionModel<number>>) => this.getMatchingFormulaColorSystems(colorSystemOptions, formulas))
    );
  }

  public getSpectralChartData(
    colorId: string,
    formulaId: string,
    filters: RecipeDetailsSpectralChartFiltersModel
  ): Observable<RecipeDetailsSpectralChartDataModel> {
    const chartFilters: RecipeDetailsSpectralChartFiltersDto = RecipeDetailsSpectralChartFiltersDtoCreator.create(filters);

    return this.recipeDetailsHttpService
      .getSpectralChartData(colorId, formulaId, chartFilters)
      .pipe(map(RecipeDetailsSpectralChartDataCreator.create.bind(this)));
  }

  public getAnglesData(
    colorId: string,
    formulaId: string,
    filters: RecipeDetailsAnglesFiltersModel
  ): Observable<RecipeDetailsAnglesDataModel> {
    const anglesFilters: RecipeDetailsAnglesFiltersDto = RecipeDetailsAnglesFiltersDtoCreator.create(filters);

    return this.recipeDetailsHttpService.getAnglesData(colorId, formulaId, anglesFilters).pipe(map(RecipeDetailsAnglesDataCreator.create));
  }

  public getRecipePrices(formulaId: number, recipePricesData: RecipePricesDataModel): Observable<Array<RecipePriceModel>> {
    const recipePricesDataDto: RecipePricesDataDto = RecipePricesDataDtoCreator.create(recipePricesData);

    return this.recipeDetailsHttpService
      .getRecipePrices(formulaId, recipePricesDataDto)
      .pipe(map((list: Array<RecipePriceDto>) => list.map(RecipePriceCreator.create)));
  }

  public getRecipeIngredients(
    formulaId: number,
    data: RecipeFormulaIngredientsDataModel
  ): Observable<Array<RecipeFormulaIngredientsModel>> {
    const recipeFormulaIngredientsDataDto: RecipeFormulaIngredientsDataDto = RecipeFormulaIngredientsDataDtoCreator.create(data);

    return this.recipeDetailsHttpService
      .getRecipeIngredients(formulaId, recipeFormulaIngredientsDataDto)
      .pipe(map((listDto: Array<RecipeFormulaIngredientsDto>) => listDto.map(RecipeFormulaIngredientsCreator.create.bind(this))));
  }

  public getRecipeIngredientsForMixing(
    formulaId: number,
    data: RecipeIngredientsForMixingDataModel
  ): Observable<Array<RecipeLayerForMixingModel>> {
    const recipeIngredientsForMixingDataDto: RecipeIngredientsForMixingDataDto = RecipeIngredientsForMixingDataDtoCreator.create(data);

    return this.recipeDetailsHttpService
      .getRecipeIngredientsForMixing(formulaId, recipeIngredientsForMixingDataDto)
      .pipe(map((listDto: Array<RecipeLayerForMixingDto>) => listDto.map(RecipeLayerForMixingCreator.create.bind(this))));
  }

  public updateRecipeFormulaIngredients(
    formulaId: number,
    isCorrection: boolean,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    recipeId?: string
  ): Observable<Array<RecipeFormulaIngredientsModel>> {
    const recipeIngredientsData: RecipeFormulaIngredientsDataModel = {
      type: recipeType,
      measurementId,
      recipeSignature: recipeId || '',
    };

    const recipeIngredientsDataDto: RecipeFormulaIngredientsDataDto = RecipeFormulaIngredientsDataDtoCreator.create(recipeIngredientsData);

    return this.recipeDetailsHttpService
      .getRecipeIngredients(formulaId, recipeIngredientsDataDto)
      .pipe(map((listDto: Array<RecipeFormulaIngredientsDto>) => listDto.map(RecipeFormulaIngredientsCreator.create.bind(this))));
  }

  private getMatchingFormulaColorSystems(
    colorSystemOptions: Array<DropdownOptionModel<number>>,
    formulas: Array<RecipeFormulaBasicModel>
  ): Array<DropdownOptionModel<number>> {
    return colorSystemOptions.filter((option: DropdownOptionModel<number>) =>
      formulas.find((formula: RecipeFormulaBasicModel) => option.value === formula.colorSystemId)
    );
  }

  private getColorSystemOptions(): Observable<Array<DropdownOptionModel<number>>> {
    return this.currentSettingsService.getColorSystemsSettings().pipe(
      map((options: Array<SettingsOptionCollectionListItemModel> | null) =>
        !options
          ? []
          : options.map((elementValue: SettingsOptionCollectionListItemModel) => ({
              value: +elementValue.key,
              label: elementValue.value,
            }))
      ),
      take(1)
    );
  }
}
