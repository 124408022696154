import { CombinationAlternativesListDataPageDto } from '../../dtos/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';
import { CombinationAlternativesListDataPageModel } from '../../models/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';

export class CombinationAlternativesListDataPageDtoCreator {
  public static create(model: CombinationAlternativesListDataPageModel): CombinationAlternativesListDataPageDto {
    return {
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.page && { page: model.page }),
    };
  }
}
