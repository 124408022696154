import { Injectable } from '@angular/core';

import { catchError, map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, filter, forkJoin, Observable, of, throwError } from 'rxjs';

import { NoResultsOptionsModel } from '../../../shared/models/no-results/no-results-options.model';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeFormulaBasicModel } from '../../models/recipe-formula-basic/recipe-formula-basic.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeFormulaIngredientsDataModel } from '../../models/recipe-formula-ingredients-data/recipe-formula-ingredients-data.model';
import { RecipeDetailsModel } from '../../models/recipe-details/recipe-details.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsSpectralChartFiltersModel } from '../../models/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { ApiErrorModel } from '../../../api-errors/models/api-error/api-error.model';
import { ToastsService } from '../../../toasts/services/toasts/toasts.service';
import { RecipeDetailsAnglesFiltersModel } from '../../models/recipe-details-angles-filters/recipe-details-angles-filters.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipePricesDataModel } from '../../models/recipe-prices-data/recipe-prices-data.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { RecipeIngredientsForMixingDataModel } from '../../../recipes/models/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.model';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { CreateMixtureLayersUtil } from '../../../mixes/utils/create-mixture-layers/create-mixture-layers.util';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { MixtureSourceTypeModel } from '../../../mixes/models/mixture-source-type/mixture-source-type.model';
import { MixtureSourceTypeEnum } from '../../../mixes/enums/mixture-source-type/mixture-source-type.enum';
import { LinkedColorsService } from '../../../linked-colors/services/linked-colors/linked-colors.service';
import { ColorCombinationsService } from '../../../color-combinations/services/color-combinations/color-combinations.service';
import { LinkedColorsDetailsModel } from '../../../linked-colors/models/linked-colors-details/linked-colors-details.model';
import { CombinationDetailsModel } from '../../../color-combinations/models/combination-details/combination-details.model';
import { RecipeListItemModel } from '../../../recipes/models/recipe-list-item/recipe-list-item.model';
import { RecipeDetailsHttpService } from '../recipe-details-http/recipe-details-http.service';
import { RecipeFormulaIngredientsDto } from '../../../recipes/dtos/recipe-formula-ingredients/recipe-formula-ingredients.dto';
import { RecipeFormulaIngredientsCreator } from '../../creators/recipe-formula-ingredients/recipe-formula-ingredients.creator';
import { RecipeIngredientsForMixingDataDto } from '../../dtos/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.dto';
import { RecipeIngredientsForMixingDataDtoCreator } from '../../creators/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data-dto.creator';
import { RecipeLayerForMixingDto } from '../../dtos/recipe-layer-for-mixing/recipe-layer-for-mixing-model.dto';
import { RecipeLayerForMixingCreator } from '../../creators/recipe-layer-for-mixing/recipe-layer-for-mixing.creator';
import { ToggleBoxOptionModel } from '../../../forms/models/toggle-box-option/toggle-box-option.model';
import { RecipeDetailsStateService } from '../recipe-details-state/recipe-details-state.service';
import { RecipeFormulaDataService } from '../recipe-formula-data/recipe-formula-data.service';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsService {
  private userColorSystem?: string;
  private readonly isTabDataError$: BehaviorSubject<boolean>;
  private readonly noDataWarning: NoResultsOptionsModel;

  constructor(
    private recipeDetailsHttpService: RecipeDetailsHttpService,
    private currentSettingsService: CurrentSettingsService,
    private linkedColorsService: LinkedColorsService,
    private colorCombinationsService: ColorCombinationsService,
    private toastsService: ToastsService,
    private recipeDetailsStateService: RecipeDetailsStateService,
    private recipeFormulaDataService: RecipeFormulaDataService
  ) {
    this.isTabDataError$ = new BehaviorSubject<boolean>(false);
    this.noDataWarning = {
      title: 'recipes.recipeDetailsModal.noRecipe.title',
      iconClass: 'info-circle-gray-icon',
      iconBackgroundClass: 'warning',
      description: 'recipes.recipeDetailsModal.noRecipe.description',
      showButton: false,
    };
  }

  public getRecipeDetails$(): Observable<RecipeListItemModel | null> {
    return this.recipeDetailsStateService.getRecipeDetails$();
  }

  public setRecipeDetails(recipe: RecipeListItemModel | null): void {
    if (!recipe) {
      return;
    }

    this.recipeDetailsStateService.updateRecipeState({
      recipe,
      colorId: recipe.colorId,
      formulaId: recipe.formulas[0].id,
    });
  }

  public getRecipeDetailsCurrentState$(): Observable<RecipeListItemModel | null> {
    return this.recipeDetailsStateService.getRecipeDetails$().pipe(filter((recipeDetails: RecipeListItemModel | null) => !!recipeDetails));
  }

  public getIsTabDataError(): BehaviorSubject<boolean> {
    return this.isTabDataError$;
  }

  public getDefaultColorSystem(): Observable<string> {
    if (this.userColorSystem) {
      return of(this.userColorSystem);
    }

    return this.currentSettingsService.getDefaultColorSystem().pipe(
      map((color: string | null) => color || ''),
      tap((color: string) => {
        this.userColorSystem = color;
      }),
      take(1)
    );
  }

  public getNoRecipeSelectedWarning(): NoResultsOptionsModel {
    return this.noDataWarning;
  }

  public getRecipeFormulaDetails(
    colorId: number,
    formulaId: number,
    recipeType: RecipeTypeModel,
    measurementId?: string
  ): Observable<RecipeFormulaDetailsModel> {
    if (!recipeType) {
      return EMPTY;
    }

    this.isTabDataError$.next(false);

    const recipeDetails: RecipeDetailsModel = {
      type: recipeType,
      measurementId,
    };

    return this.recipeFormulaDataService.getRecipeFormulaDetails(colorId, formulaId, recipeDetails).pipe(
      tap((recipeDetails: RecipeFormulaDetailsModel) => this.recipeDetailsStateService.setRecipeFormulaDetails(recipeDetails)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipeFormulaDetails$(): Observable<RecipeFormulaDetailsModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaDetails$();
  }

  public getRecipeIngredients(
    formulaId: number,
    type: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string
  ): Observable<Array<RecipeFormulaIngredientsModel>> {
    this.isTabDataError$.next(false);

    if (!formulaId) {
      return EMPTY;
    }

    const recipeFormulaIngredients: RecipeFormulaIngredientsDataModel = {
      type,
      measurementId,
      recipeSignature,
    };

    return this.recipeFormulaDataService.getRecipeIngredients(formulaId, recipeFormulaIngredients).pipe(
      tap((recipeFormulaIngredientsData: Array<RecipeFormulaIngredientsModel>) => {
        this.recipeDetailsStateService.setRecipeFormulaIngredients(recipeFormulaIngredientsData);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipeFormulaIngredients$(): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeDetailsStateService.getRecipeFormulaIngredients$();
  }

  public getRecipeIngredientsForMixing(
    formulaId: number,
    recipeIngredientsForMixingDataModel: RecipeIngredientsForMixingDataModel
  ): Observable<Array<RecipeLayerForMixingModel>> {
    const recipeIngredientsForMixingDataDto: RecipeIngredientsForMixingDataDto =
      RecipeIngredientsForMixingDataDtoCreator.create(recipeIngredientsForMixingDataModel);

    return this.recipeDetailsHttpService.getRecipeIngredientsForMixing(formulaId, recipeIngredientsForMixingDataDto).pipe(
      map((listDto: Array<RecipeLayerForMixingDto>) => {
        return listDto.map((dto: RecipeLayerForMixingDto) => RecipeLayerForMixingCreator.create(dto));
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipeCorrectionIngredients(measurementId: string, recipeSignature: string): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeDetailsHttpService.getRecipeCorrectionIngredients(measurementId, recipeSignature).pipe(
      map((listDto: Array<RecipeFormulaIngredientsDto>) =>
        listDto.map((dto: RecipeFormulaIngredientsDto) => RecipeFormulaIngredientsCreator.create(dto))
      ),
      tap((recipeFormulaIngredientsData: Array<RecipeFormulaIngredientsModel>) => {
        this.recipeDetailsStateService.setRecipeFormulaIngredients(recipeFormulaIngredientsData);
      })
    );
  }

  public getSpectralChartData(
    colorId: string,
    formulaId: string,
    filters: RecipeDetailsSpectralChartFiltersModel
  ): Observable<RecipeDetailsSpectralChartDataModel> {
    this.isTabDataError$.next(false);

    return this.recipeFormulaDataService.getSpectralChartData(colorId, formulaId, filters).pipe(
      tap((recipeDetailsSpectralChartData: RecipeDetailsSpectralChartDataModel) => {
        this.recipeDetailsStateService.setRecipeFormulaSpectralChart(recipeDetailsSpectralChartData);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);
        return throwError(() => apiError);
      })
    );
  }

  public getSpectralChartData$(): Observable<RecipeDetailsSpectralChartDataModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaSpectralChart$();
  }

  public getAnglesData(
    colorId: string,
    formulaId: string,
    filters: RecipeDetailsAnglesFiltersModel
  ): Observable<RecipeDetailsAnglesDataModel> {
    this.isTabDataError$.next(false);

    return this.recipeFormulaDataService.getAnglesData(colorId, formulaId, filters).pipe(
      tap((recipeDetailsAnglesData: RecipeDetailsAnglesDataModel) => {
        this.recipeDetailsStateService.setRecipeFormulaAngles(recipeDetailsAnglesData);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);
        return throwError(() => apiError);
      })
    );
  }

  public getAnglesData$(): Observable<RecipeDetailsAnglesDataModel | null> {
    return this.recipeDetailsStateService.getRecipeFormulaAngles$();
  }

  public getRecipePrices(
    formulaId: number,
    recipePriceData: RecipePriceFormModel,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string
  ): Observable<Array<RecipePriceModel>> {
    this.isTabDataError$.next(false);

    if (!formulaId || !recipePriceData) {
      return EMPTY;
    }

    const recipePricesData: RecipePricesDataModel = {
      amount: recipePriceData.amount,
      unit: recipePriceData.unit,
      type: recipeType,
      measurementId,
      recipeSignature,
    };

    return this.recipeFormulaDataService.getRecipePrices(formulaId, recipePricesData).pipe(
      tap((prices: Array<RecipePriceModel>) => this.recipeDetailsStateService.setRecipeFormulaPrices(prices)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getRecipePriceLists$(): Observable<Array<RecipePriceModel>> {
    return this.recipeDetailsStateService.getRecipeFormulaPrices$();
  }

  public getLinkedColorData(
    brandId: number | null,
    colorId: number | null,
    colorSystemId: number | null,
    formulaId: number
  ): Observable<LinkedColorsDetailsModel> {
    this.isTabDataError$.next(false);

    return this.linkedColorsService.getLinkedColorsDetails(brandId || 0, colorId || 0, colorSystemId || 0, formulaId).pipe(
      tap((details: LinkedColorsDetailsModel) => this.recipeDetailsStateService.setLinkedColorsDetails(details)),
      catchError((apiError: ApiErrorModel) => {
        if (apiError.status !== 404) {
          this.toastsService.showError(apiError.message);
        }

        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getLinkedColorData$(): Observable<LinkedColorsDetailsModel | null> {
    return this.recipeDetailsStateService.getLinkedColorsDetails$();
  }

  public getColorCombinationData(
    brandId: number | null,
    colorId: number | null,
    colorSystemId: number | null
  ): Observable<CombinationDetailsModel> {
    this.isTabDataError$.next(false);

    return this.colorCombinationsService.getCombinationDetails(brandId || 0, colorId || 0, colorSystemId || 0).pipe(
      tap((details: CombinationDetailsModel) => this.recipeDetailsStateService.setColorCombinationDetails(details)),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);

        return throwError(() => apiError);
      })
    );
  }

  public getColorCombinationData$(): Observable<CombinationDetailsModel | null> {
    return this.recipeDetailsStateService.getColorCombinationDetails$();
  }

  public getNoColorSystemSelectedWarning(): NoResultsOptionsModel {
    return {
      ...this.noDataWarning,
      title: 'recipes.recipeDetailsModal.noColor.title',
      description: 'recipes.recipeDetailsModal.noColor.description',
    };
  }

  public getNoLinkedColorsWarning(): NoResultsOptionsModel {
    return {
      ...this.noDataWarning,
      title: 'recipes.recipeDetailsModal.noLinkedColors.title',
      description: 'recipes.recipeDetailsModal.noLinkedColors.description',
    };
  }

  public getRecipeDetailsToPrepareMixture(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    recipeId: string,
    measurementId?: string,
    repairId?: string
  ): Observable<PrepareMixtureStateModel> {
    const recipeDetails: RecipeDetailsModel = {
      type: recipeType,
      measurementId: measurementId,
    };

    const recipeIngredientsForMixingData: RecipeIngredientsForMixingDataModel = {
      type: recipeType,
      measurementId: measurementId,
      recipeSignature: recipeId,
    };

    return forkJoin([
      this.getRecipeFormulaDetails(colorId, formulaId, recipeDetails.type, measurementId),
      this.getRecipeIngredientsForMixing(formulaId, recipeIngredientsForMixingData),
    ]).pipe(
      map(([details, layers]: [RecipeFormulaDetailsModel, Array<RecipeLayerForMixingModel>]) => {
        return {
          createMixture: {
            source: {
              id: details.id,
              type: this.getMixtureSourceType(recipeType),
            },
            layers: CreateMixtureLayersUtil.createAddMixtureLayers(layers),
            layersNumber: layers.length,
            ...(repairId && { repairId: repairId }),
          },
        };
      })
    );
  }

  public getRecipeTypeOptions(): Array<ToggleBoxOptionModel<RecipeTypeModel>> {
    return [
      {
        label: 'recipes.recipeDetailsModal.recipeTypeOptions.novolRecipe',
        value: RecipeTypeEnum.novolRecipe,
        isDisabled: false,
      },
      {
        label: 'recipes.recipeDetailsModal.recipeTypeOptions.correction',
        value: RecipeTypeEnum.correction,
        isDisabled: true,
      },
    ];
  }

  public getFormulasColorSystems(formulas: Array<RecipeFormulaBasicModel>): Observable<Array<DropdownOptionModel<number>>> {
    if (!formulas?.length) {
      return of([]);
    }

    return this.recipeFormulaDataService.getFormulasColorSystems(formulas).pipe(
      tap((colorSystems: Array<DropdownOptionModel<number>>) => {
        this.recipeDetailsStateService.setFormulaColorOptions(colorSystems);
      })
    );
  }

  public getRecipeSpectralChart(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>
  ): Observable<void> {
    this.isTabDataError$.next(false);

    if (!colorId || !formulaId) {
      return EMPTY;
    }

    const filters: RecipeDetailsSpectralChartFiltersModel = {
      type: recipeType || RecipeTypeEnum.novolRecipe,
      measurementId: measurementId || '',
      geometries: geometries?.length ? geometries : [],
    };

    return this.recipeFormulaDataService.getSpectralChartData(colorId.toString(), formulaId.toString(), filters).pipe(
      tap((data: RecipeDetailsSpectralChartDataModel) => {
        this.recipeDetailsStateService.setRecipeFormulaSpectralChart(data);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);
        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  public getRecipeAngles(
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>,
    includesCorrection?: boolean
  ): Observable<void> {
    this.isTabDataError$.next(false);

    if (!colorId || !formulaId) {
      return EMPTY;
    }

    const filters: RecipeDetailsAnglesFiltersModel = {
      measurementId: measurementId || '',
      geometries: geometries?.length ? geometries : [],
      includesCorrection: !!includesCorrection,
    };

    return this.recipeFormulaDataService.getAnglesData(colorId.toString(), formulaId.toString(), filters).pipe(
      tap((data: RecipeDetailsAnglesDataModel) => {
        this.recipeDetailsStateService.setRecipeFormulaAngles(data);
      }),
      catchError((apiError: ApiErrorModel) => {
        this.toastsService.showError(apiError.message);
        this.isTabDataError$.next(true);
        return throwError(() => apiError);
      }),
      map(() => void 0)
    );
  }

  private getMixtureSourceType(recipeType: RecipeTypeModel): MixtureSourceTypeModel {
    if (recipeType === RecipeTypeEnum.correction) {
      return MixtureSourceTypeEnum.correction;
    }

    return MixtureSourceTypeEnum.recipe;
  }
}
