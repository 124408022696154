import { CombinationAlternativeListItemDto } from '../../dtos/combination-alternative-list-item/combination-alternative-list-item.dto';
import { CombinationAlternativeListItemModel } from '../../models/combination-alternative-list-item/combination-alternative-list-item.model';

export class CombinationAlternativeListItemCreator {
  public static create(dto: CombinationAlternativeListItemDto): CombinationAlternativeListItemModel {
    return {
      alternative: dto.alternative,
      colorCode: dto.color_code,
      colorId: dto.color_id,
      colorName: dto.color_name,
      formulaId: dto.formula_id,
      thumbnail: dto.thumbnail,
    };
  }
}
