import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { LinkedColorsHttpService } from '../linked-colors-http/linked-colors-http.service';
import { LinkedColorsDetailsModel } from '../../models/linked-colors-details/linked-colors-details.model';
import { LinkedColorsDetailsDto } from '../../dtos/linked-colors-details/linked-colors-details.dto';
import { LinkedColorsDetailsCreator } from '../../creators/linked-colors-details/linked-colors-details.creator';
import { ListModel } from '../../../shared/models/list/list.model';
import { LinkedColorAlternativeListItemDto } from '../../dtos/linked-color-alternative-list-item/linked-color-alternative-list-item.dto';
import { LinkedColorAlternativesListDataPageDto } from '../../dtos/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.dto';
import { LinkedColorAlternativeListItemModel } from '../../models/linked-color-alternative-list-item/linked-color-alternative-list-item.model';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { LinkedColorAlternativesListDataPageModel } from '../../models/linked-color-alternatives-list-data-page/linked-color-alternatives-list-data-page.model';
import { LinkedColorAlternativesListDataPageDtoCreator } from '../../creators/linked-color-alternatives-list-data-page-dto/linked-color-alternatives-list-data-page-dto.creator';
import { LinkedColorAlternativeListItemCreator } from '../../creators/linked-color-alternative-list-item/linked-color-alternative-list-item.creator';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { DownloadFileModel } from '../../../shared/models/download-file/download-file.model';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DownloadFileCreator } from '../../../shared/creators/download-file/download-file.creator';

@Injectable({
  providedIn: 'root',
})
export class LinkedColorsService {
  constructor(private linkedColorsHttpService: LinkedColorsHttpService) {}

  public getLinkedColorsDetails(
    brandId: number,
    colorId: number,
    colorSystemId: number,
    formulaId: number
  ): Observable<LinkedColorsDetailsModel> {
    return this.linkedColorsHttpService
      .getLinkedColorsDetails(brandId, colorId, colorSystemId, formulaId)
      .pipe(map((dto: LinkedColorsDetailsDto) => LinkedColorsDetailsCreator.create(dto)));
  }

  public getLinkedColorAlternativesList(
    id: string,
    colorId: number,
    linkedColorAlternativesListDataPage: LinkedColorAlternativesListDataPageModel
  ): Observable<ListModel<LinkedColorAlternativeListItemModel>> {
    const linkedColorAlternativesListDataPageDto: LinkedColorAlternativesListDataPageDto =
      LinkedColorAlternativesListDataPageDtoCreator.create(linkedColorAlternativesListDataPage);

    return this.linkedColorsHttpService.getLinkedColorAlternativesList(id, colorId, linkedColorAlternativesListDataPageDto).pipe(
      map((listDto: ListDto<LinkedColorAlternativeListItemDto>) => {
        const items: Array<LinkedColorAlternativeListItemModel> = listDto.items.map((dto: LinkedColorAlternativeListItemDto) =>
          LinkedColorAlternativeListItemCreator.create(dto)
        );

        return ListCreator.create<LinkedColorAlternativeListItemDto, LinkedColorAlternativeListItemModel>(listDto, items);
      })
    );
  }

  public exportLinkedColorToPdf(id: string): Observable<DownloadFileModel> {
    return this.linkedColorsHttpService
      .exportLinkedColorToPdf(id)
      .pipe(map((downloadFileDto: DownloadFileDto) => DownloadFileCreator.create(downloadFileDto)));
  }
}
