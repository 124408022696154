import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ColorCombinationsHttpService } from '../color-combinations-http/color-combinations-http.service';
import { CombinationDetailsDto } from '../../dtos/combination-details/combination-details.dto';
import { ListModel } from '../../../shared/models/list/list.model';
import { CombinationAlternativesListDataPageDto } from '../../dtos/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';
import { CombinationAlternativeListItemDto } from '../../dtos/combination-alternative-list-item/combination-alternative-list-item.dto';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { CombinationAlternativeListItemModel } from '../../models/combination-alternative-list-item/combination-alternative-list-item.model';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { CombinationAlternativesListDataPageModel } from '../../models/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';
import { CombinationDetailsModel } from '../../models/combination-details/combination-details.model';
import { CombinationDetailsCreator } from '../../creators/combination-details/combination-details.creator';
import { CombinationAlternativeListItemCreator } from '../../creators/combination-alternative-list-item/combination-alternative-list-item.creator';
import { CombinationAlternativesListDataPageDtoCreator } from '../../creators/combination-alternatives-list-data-page/combination-alternatives-list-data-page.creator';
import { DownloadFileDto } from '../../../shared/dtos/download-file/download-file.dto';
import { DownloadFileModel } from '../../../shared/models/download-file/download-file.model';
import { DownloadFileCreator } from '../../../shared/creators/download-file/download-file.creator';

@Injectable({
  providedIn: 'root',
})
export class ColorCombinationsService {
  constructor(private colorCombinationsHttpService: ColorCombinationsHttpService) {}

  public getCombinationDetails(brandId: number, colorId: number, colorSystemId: number): Observable<CombinationDetailsModel> {
    return this.colorCombinationsHttpService
      .getCombinationDetails(brandId, colorId, colorSystemId)
      .pipe(map((dto: CombinationDetailsDto) => CombinationDetailsCreator.create(dto)));
  }

  public getCombinationAlternativesList(
    id: string,
    colorId: number,
    combinationAlternativesListDataPage: CombinationAlternativesListDataPageModel
  ): Observable<ListModel<CombinationAlternativeListItemModel>> {
    const combinationAlternativesListDataPageDto: CombinationAlternativesListDataPageDto =
      CombinationAlternativesListDataPageDtoCreator.create(combinationAlternativesListDataPage);

    return this.colorCombinationsHttpService.getCombinationAlternativesList(id, colorId, combinationAlternativesListDataPageDto).pipe(
      map((listDto: ListDto<CombinationAlternativeListItemDto>) => {
        const items: Array<CombinationAlternativeListItemModel> = listDto.items.map((dto: CombinationAlternativeListItemDto) =>
          CombinationAlternativeListItemCreator.create(dto)
        );
        return ListCreator.create<CombinationAlternativeListItemDto, CombinationAlternativeListItemModel>(listDto, items);
      })
    );
  }

  public exportCombinationToPdf(id: string): Observable<DownloadFileModel> {
    return this.colorCombinationsHttpService
      .exportCombinationToPdf(id)
      .pipe(map((downloadFileDto: DownloadFileDto) => DownloadFileCreator.create(downloadFileDto)));
  }
}
