<figure class="chart-line" *ngIf="options?.series?.length">
  <highcharts-chart
    class="chart"
    constructorType="chart"
    [Highcharts]="Highcharts"
    [options]="options"
    [oneToOne]="true"
    [(update)]="isUpdate"
  ></highcharts-chart>
</figure>
