import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RecipeTypeModel } from '../../../recipe-details/models/recipe-type/recipe-type.model';
import { RecipeDetailsService } from '../../../recipe-details/services/recipe-details/recipe-details.service';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';
import { ModalService } from '../../../modal/services/modal.service';
import { RecipeTypeEnum } from '../../enums/recipe-type/recipe-type.enum';

@Component({
  selector: 'app-check-recipe-type-modal[recipeType][colorId][formulaId][recipeId]',
  templateUrl: './check-recipe-type-modal.component.html',
  styleUrls: ['./check-recipe-type-modal.component.scss'],
})
export class CheckRecipeTypeModalComponent {
  @Input() public colorId!: number;
  @Input() public formulaId!: number;
  @Input() public recipeId!: string;
  @Input() public measurementId?: string;
  @Input() public repairId?: string;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private recipeDetailsService: RecipeDetailsService,
    private router: Router
  ) {}

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public selectOriginal(): void {
    this.navigateToPrepareMixturePage(RecipeTypeEnum.novolRecipe);
  }

  public selectCorrection(): void {
    this.navigateToPrepareMixturePage(RecipeTypeEnum.correction);
  }

  private navigateToPrepareMixturePage(recipeType: RecipeTypeModel): void {
    this.recipeDetailsService
      .getRecipeDetailsToPrepareMixture(recipeType, this.colorId, this.formulaId, this.recipeId, this.measurementId, this.repairId)
      .subscribe((prepareMixtureState: PrepareMixtureStateModel) => {
        const locationState: CanDeactivateGuardModel<PrepareMixtureStateModel> = {
          canDeactivate: true,
          data: prepareMixtureState,
        };

        this.router.navigate(['/mixes/prepare-mixture'], { state: LocationGetStateUtil.createState(locationState) }).then(() => {
          this.modalService.dismissAll();
        });
      });
  }
}
