<div class="tabs">
  <ng-container *ngFor="let tab of tabs">
    <ng-container *appUserHasPermission="tab.permission">
      <div class="tab" [class.active]="isTabActive$(tab) | async">
        <button class="button tab-button gray-link" [disabled]="isTabDisabled$(tab) | async" (click)="emitTabButtonClick(tab)">
          <span class="label">{{ tab.label | translate }}</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
