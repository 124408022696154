import { RecipeDetailsAngleHexColorDto } from '../../dtos/recipe-details-angle-hex-color/recipe-details-angle-hex-color.dto';
import { RecipeDetailsAngleHexColorModel } from '../../models/recipe-details-angle-hex-color/recipe-details-angle-hex-color.model';

export class RecipeDetailsAngleHexColorCreator {
  public static create(dto: RecipeDetailsAngleHexColorDto): RecipeDetailsAngleHexColorModel {
    return {
      name: dto.name,
      hexColor: dto.hex_color,
    };
  }
}
