import { RecipePriceDto } from '../../dtos/recipe-price/recipe-price.dto';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipePriceIngredientDto } from '../../dtos/recipe-price-ingredient/recipe-price-ingredient.dto';

export class RecipePriceCreator {
  public static create(dto: RecipePriceDto): RecipePriceModel {
    return {
      layerNumber: dto.layer_number,
      sellingPrice: dto.selling_price,
      sellingPriceWithAddons: dto.selling_price_with_addons,
      ingredients: dto.ingredients.map((ingredient: RecipePriceIngredientDto) => {
        return {
          id: ingredient.id,
          name: ingredient.name,
          amount: ingredient.amount,
          sellingPrice: ingredient.selling_price,
          thumbnail: ingredient.thumbnail,
          isSpecialIngredient: ingredient.special_ingredient,
          isPowder: ingredient.powder,
          isComponentWithdraw: ingredient.component_withdraw,
          isHardener: ingredient.hardener,
          isThinner: ingredient.thinner,
        };
      }),
    };
  }
}
