import { RecipeLayerForMixingDto } from '../../dtos/recipe-layer-for-mixing/recipe-layer-for-mixing-model.dto';
import { RecipeLayerIngredientForMixingDto } from '../../../recipes/dtos/recipe-layer-ingredient-for-mixing/recipe-layer-ingredient-for-mixing.dto';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { RecipeLayerIngredientForMixingCreator } from '../../../recipes/creators/recipe-layer-ingredient-for-mixing/recipe-layer-ingredient-for-mixing.creator';

export class RecipeLayerForMixingCreator {
  public static create(dto: RecipeLayerForMixingDto): RecipeLayerForMixingModel {
    return {
      hasHardener: dto.has_hardener,
      hasThinner: dto.has_thinner,
      layerNumber: dto.layer_number,
      ingredients: dto.ingredients.map((ingredient: RecipeLayerIngredientForMixingDto) =>
        RecipeLayerIngredientForMixingCreator.create(ingredient)
      ),
    };
  }
}
