import { LinkedColorDto } from '../../dtos/linked-color/linked-color.dto';
import { LinkedColorModel } from '../../models/linked-color/linked-color.model';

export class LinkedColorCreator {
  public static create(dto: LinkedColorDto): LinkedColorModel {
    return {
      code: dto.code,
      id: dto.id,
      name: dto.name,
      occurrences: dto.occurrences,
      thumbnail: dto.thumbnail,
    };
  }
}
