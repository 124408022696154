<div class="recipe-linked-colors">
  <div class="recipe-linked-colors-details">
    <span class="label">{{ 'linkedColor.recipeLinkedColors.details.label.brand' | translate }}</span>

    <span class="value">{{ linkedColorsDetails.brand }}</span>
  </div>

  <app-table class="recipe-linked-colors-table" [isScrollEnabled]="false">
    <app-table-header>
      <app-table-header-cell class="table-header-cell">
        {{ 'linkedColor.recipeLinkedColors.table.header.thumbnail' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'linkedColor.recipeLinkedColors.table.header.code' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'linkedColor.recipeLinkedColors.table.header.name' | translate }}
      </app-table-header-cell>

      <app-table-header-cell class="table-header-cell">
        {{ 'linkedColor.recipeLinkedColors.table.header.occurrences' | translate }}
      </app-table-header-cell>
    </app-table-header>

    <ng-container ngProjectAs="app-table-row" *ngIf="linkedColorsDetails">
      <app-table-row *ngFor="let color of linkedColorsDetails.colors" [canToggle]="true" [detailsTemplate]="detailsTemplate">
        <app-table-row-cell>
          <app-color-thumbnail [color]="color.thumbnail"></app-color-thumbnail>
        </app-table-row-cell>

        <app-table-row-cell>{{ color.code }}</app-table-row-cell>

        <app-table-row-cell>{{ color.name }}</app-table-row-cell>

        <app-table-row-cell>{{ color.occurrences }}</app-table-row-cell>

        <ng-template #detailsTemplate>
          <app-linked-color-list-item-details
            [id]="linkedColorsDetails.id"
            [colorId]="color.id"
            [recipeId]="recipeId"
            [repairId]="repairId"
            (openDetailsModalClicked)="openRecipeDetailsModal($event)"
          ></app-linked-color-list-item-details>
        </ng-template>
      </app-table-row>
    </ng-container>
  </app-table>
</div>
