import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { RecipeListItemModel } from '../../../recipes/models/recipe-list-item/recipe-list-item.model';
import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeFormulaBasicModel } from '../../models/recipe-formula-basic/recipe-formula-basic.model';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { LinkedColorsDetailsModel } from '../../../linked-colors/models/linked-colors-details/linked-colors-details.model';
import { CombinationDetailsModel } from '../../../color-combinations/models/combination-details/combination-details.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsStateService {
  private readonly recipeFormulaDetails$: BehaviorSubject<RecipeFormulaDetailsModel | null>;
  private readonly recipeFormulaIngredients$: BehaviorSubject<Array<RecipeFormulaIngredientsModel>>;
  private readonly recipeFormulaSpectralChart$: BehaviorSubject<RecipeDetailsSpectralChartDataModel | null>;
  private readonly recipeFormulaAngles$: BehaviorSubject<RecipeDetailsAnglesDataModel | null>;
  private readonly recipeDetails$: BehaviorSubject<RecipeListItemModel | null>;
  private readonly formulaId$: BehaviorSubject<number>;
  private readonly colorId$: BehaviorSubject<number>;
  private readonly formulaColorOptions$: BehaviorSubject<Array<DropdownOptionModel<number>>>;
  private readonly isColorSystemFormDisabled$: BehaviorSubject<boolean>;
  private readonly linkedColorsDetailsSubject$: BehaviorSubject<LinkedColorsDetailsModel | null>;
  private readonly colorCombinationDetails$: BehaviorSubject<CombinationDetailsModel | null>;
  private readonly recipeFormulaPricesSubject$: BehaviorSubject<Array<RecipePriceModel>>;

  constructor(private readonly currentSettingsService: CurrentSettingsService) {
    this.recipeFormulaPricesSubject$ = new BehaviorSubject<Array<RecipePriceModel>>([]);
    this.recipeDetails$ = new BehaviorSubject<RecipeListItemModel | null>(null);
    this.formulaId$ = new BehaviorSubject<number>(0);
    this.colorId$ = new BehaviorSubject<number>(0);
    this.formulaColorOptions$ = new BehaviorSubject<Array<DropdownOptionModel<number>>>([]);
    this.isColorSystemFormDisabled$ = new BehaviorSubject<boolean>(false);
    this.linkedColorsDetailsSubject$ = new BehaviorSubject<LinkedColorsDetailsModel | null>(null);
    this.colorCombinationDetails$ = new BehaviorSubject<CombinationDetailsModel | null>(null);
    this.recipeFormulaDetails$ = new BehaviorSubject<RecipeFormulaDetailsModel | null>(null);
    this.recipeFormulaIngredients$ = new BehaviorSubject<Array<RecipeFormulaIngredientsModel>>([]);
    this.recipeFormulaSpectralChart$ = new BehaviorSubject<RecipeDetailsSpectralChartDataModel | null>(null);
    this.recipeFormulaAngles$ = new BehaviorSubject<RecipeDetailsAnglesDataModel | null>(null);
  }

  public updateRecipeState(state: { recipe: RecipeDetailsModalParamsModel; colorId: number; formulaId: number }): void {
    if (this.recipeDetails$.value === state.recipe) {
      return;
    }

    this.recipeDetails$.next(state.recipe as RecipeListItemModel);
    this.colorId$.next(state.colorId);
    this.formulaId$.next(state.formulaId);
  }

  public getRecipeDetails$(): Observable<RecipeListItemModel | null> {
    return this.recipeDetails$.asObservable();
  }

  public getRecipeDetails(): RecipeListItemModel | null {
    return this.recipeDetails$.getValue();
  }

  public setFormulaId(id: number): void {
    this.formulaId$.next(id);
  }

  public getFormulaId$(): Observable<number> {
    return this.formulaId$.asObservable();
  }

  public setColorId(id: number): void {
    this.colorId$.next(id);
  }

  public getColorId$(): Observable<number> {
    return this.colorId$.asObservable();
  }

  public setFormulaColorOptions(options: Array<DropdownOptionModel<number>>): void {
    this.formulaColorOptions$.next(options);
  }

  public getFormulaColorOptions$(): Observable<Array<DropdownOptionModel<number>>> {
    return this.formulaColorOptions$.asObservable();
  }

  public setIsColorSystemFormDisabled(isDisabled: boolean): void {
    this.isColorSystemFormDisabled$.next(isDisabled);
  }

  public getIsColorSystemFormDisabled$(): Observable<boolean> {
    return this.isColorSystemFormDisabled$.asObservable();
  }

  public getFormulaColorId(formulas?: Array<RecipeFormulaBasicModel>, colorSystemId?: number): number {
    if (!formulas?.length || !colorSystemId) {
      return 0;
    }

    return formulas.find((formula: RecipeFormulaBasicModel) => formula.colorSystemId === colorSystemId)?.id || 0;
  }

  public getDefaultColorboxKey(): number {
    return Number(this.currentSettingsService.getDefaultColorboxKey());
  }

  public setColorCombinationDetails(details: CombinationDetailsModel): void {
    this.colorCombinationDetails$.next(details);
  }

  public getColorCombinationDetails$(): Observable<CombinationDetailsModel | null> {
    return this.colorCombinationDetails$.asObservable();
  }

  public setRecipeFormulaPrices(prices: Array<RecipePriceModel>): void {
    this.recipeFormulaPricesSubject$.next(prices);
  }

  public getRecipeFormulaPrices$(): Observable<Array<RecipePriceModel>> {
    return this.recipeFormulaPricesSubject$.asObservable();
  }

  public setRecipeFormulaDetails(details: RecipeFormulaDetailsModel | null): void {
    this.recipeFormulaDetails$.next(details);
  }

  public getRecipeFormulaDetails$(): Observable<RecipeFormulaDetailsModel | null> {
    return this.recipeFormulaDetails$.asObservable();
  }

  public setRecipeFormulaIngredients(ingredients: Array<RecipeFormulaIngredientsModel>): void {
    this.recipeFormulaIngredients$.next(ingredients);
  }

  public getRecipeFormulaIngredients$(): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeFormulaIngredients$.asObservable();
  }

  public setRecipeFormulaSpectralChart(data: RecipeDetailsSpectralChartDataModel | null): void {
    this.recipeFormulaSpectralChart$.next(data);
  }

  public getRecipeFormulaSpectralChart$(): Observable<RecipeDetailsSpectralChartDataModel | null> {
    return this.recipeFormulaSpectralChart$.asObservable();
  }

  public setRecipeFormulaAngles(data: RecipeDetailsAnglesDataModel | null): void {
    this.recipeFormulaAngles$.next(data);
  }

  public getRecipeFormulaAngles$(): Observable<RecipeDetailsAnglesDataModel | null> {
    return this.recipeFormulaAngles$.asObservable();
  }

  public setLinkedColorsDetails(details: LinkedColorsDetailsModel | null): void {
    this.linkedColorsDetailsSubject$.next(details);
  }

  public getLinkedColorsDetails$(): Observable<LinkedColorsDetailsModel | null> {
    return this.linkedColorsDetailsSubject$.asObservable();
  }

  public resetState(): void {
    this.formulaId$.next(0);
    this.colorId$.next(0);
    this.formulaColorOptions$.next([]);
    this.isColorSystemFormDisabled$.next(false);
    this.recipeDetails$.next(null);
    this.recipeFormulaDetails$.next(null);
    this.recipeFormulaIngredients$.next([]);
    this.recipeFormulaSpectralChart$.next(null);
    this.recipeFormulaAngles$.next(null);
    this.linkedColorsDetailsSubject$.next(null);
    this.colorCombinationDetails$.next(null);
    this.recipeFormulaPricesSubject$.next([]);
  }
}
