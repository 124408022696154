import { RecipeDetailsSpectralChartDataMeasurementDto } from '../../dtos/recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.dto';
import { RecipeDetailsSpectralChartDataDto } from '../../dtos/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.dto';
import { RecipeDetailsSpectralChartDataMeasurementModel } from '../../models/recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsSpectralChartDataMeasurementCreator } from '../recipe-details-spectral-chart-data-measurement/recipe-details-spectral-chart-data-measurement.creator';
import { RecipeDetailsGeometryCreator } from '../recipe-details-geometry/recipe-details-geometry.creator';
import { RecipeDetailsGeometryModel } from '../../models/recipe-details-geometry/recipe-details-geometry.model';
import { RecipeDetailsGeometryDto } from '../../dtos/recipe-details-geometry/recipe-details-geometry.dto';

export class RecipeDetailsSpectralChartDataCreator {
  public static create(dto: RecipeDetailsSpectralChartDataDto): RecipeDetailsSpectralChartDataModel {
    return {
      geometries: RecipeDetailsSpectralChartDataCreator.getGeometries(dto.geometries),
      measurements: RecipeDetailsSpectralChartDataCreator.getMeasurements(dto.measurements),
      spectralGradientColors: dto.spectral_gradient_colors,
      wavelengths: dto.wave_lengths,
    };
  }

  private static getMeasurements(
    measurements: Array<RecipeDetailsSpectralChartDataMeasurementDto>
  ): Array<RecipeDetailsSpectralChartDataMeasurementModel> {
    return measurements.map(RecipeDetailsSpectralChartDataMeasurementCreator.create.bind(this));
  }

  private static getGeometries(geometries: Array<RecipeDetailsGeometryDto>): Array<RecipeDetailsGeometryModel> {
    return geometries.map(RecipeDetailsGeometryCreator.create.bind(this));
  }
}
