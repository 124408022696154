<div class="measuring-angles-form">
  <form class="measuring-angles-form-container">
    <label class="label subtitle-2">{{ 'mixes.measuringAnglesForm.label.measuringAngles' | translate }}</label>

    <div class="checkboxes" *ngIf="measurementAngles?.length">
      <app-form-control-checkbox
        *ngFor="let angle of measurementAngles"
        [label]="angle.label"
        [checkedValue]="true"
        [uncheckedValue]="false"
        [(ngModel)]="angle.checked"
        [ngModelOptions]="{ standalone: true }"
        [isDisabled]="angle.checked && isSelectedCheckboxDisabled"
        (ngModelChange)="emitSelectedGeometriesIds()"
      ></app-form-control-checkbox>
    </div>
  </form>
</div>
