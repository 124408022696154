import { RecipeIngredientsForMixingDataDto } from '../../dtos/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.dto';
import { RecipeIngredientsForMixingDataModel } from '../../../recipes/models/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.model';

export class RecipeIngredientsForMixingDataDtoCreator {
  public static create(model: RecipeIngredientsForMixingDataModel): RecipeIngredientsForMixingDataDto {
    return {
      ...(model.amount && { amount: model.amount }),
      ...(model.unit && { unit: model.unit }),
      ...(model.isRfu !== undefined && { rfu: model.isRfu }),
      ...(model.isThinner !== undefined && { thinner: model.isThinner }),
      ...(model.isHardener !== undefined && { hardener: model.isHardener }),
      type: model.type,
      ...(model.measurementId && { measurement_id: model.measurementId }),
      ...(model.recipeSignature && { recipeSignature: model.recipeSignature }),
    };
  }
}
