import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';
import { UserPermissionNameEnum } from '../../../user-roles/enums/user-permission-name/user-permission-name.enum';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsTabsService {
  public activeTab$: Observable<RecipeDetailsTabModel>;

  private readonly recipeDetailsTabs$: BehaviorSubject<Array<RecipeDetailsTabModel>>;
  private readonly activeTabIndexSubject$: BehaviorSubject<number>;

  constructor() {
    this.recipeDetailsTabs$ = new BehaviorSubject<Array<RecipeDetailsTabModel>>(this.getRecipeDetailsTabs());
    this.activeTabIndexSubject$ = new BehaviorSubject<number>(
      Number(localStorage.getItem('recipeDetailsActiveTab')) || RecipeDetailsTabsEnum.info
    );

    this.activeTab$ = combineLatest([this.activeTabIndexSubject$, this.recipeDetailsTabs$]).pipe(
      map(
        ([activeIndex, tabs]: [number, Array<RecipeDetailsTabModel>]) =>
          tabs.find((tab: RecipeDetailsTabModel) => tab.id === activeIndex) || tabs[0]
      ),
      distinctUntilChanged((prev: RecipeDetailsTabModel, curr: RecipeDetailsTabModel) => prev?.id === curr?.id)
    );
  }

  public initTabs(recipeDetails: RecipeDetailsModalParamsModel | null, measurementId?: string): void {
    const recipeDetailsTabs: Array<RecipeDetailsTabModel> = this.getRecipeDetailsTabs()
      .map((tab: RecipeDetailsTabModel) => ({
        ...tab,
        disabled: this.getIsTabDisabled(tab, measurementId),
      }))
      .filter((tab: RecipeDetailsTabModel) => this.getIsTabVisible(tab, recipeDetails, measurementId));

    this.recipeDetailsTabs$.next(recipeDetailsTabs);
  }

  public setActiveTab(tabIndex: number): void {
    localStorage.setItem('recipeDetailsActiveTab', tabIndex.toString());
    this.activeTabIndexSubject$.next(tabIndex);
  }

  public getRecipeDetailsTabs$(): Observable<Array<RecipeDetailsTabModel>> {
    return this.recipeDetailsTabs$.asObservable();
  }

  public getIsTabDisabled(tab: RecipeDetailsTabModel, measurementId?: string): boolean {
    const isSpectralChartOrAnglesTab: boolean = [RecipeDetailsTabsEnum.chart, RecipeDetailsTabsEnum.preview].includes(tab.id);

    if (isSpectralChartOrAnglesTab) {
      return !measurementId || tab.disabled;
    }

    return tab.disabled;
  }

  public resetActiveTab(): void {
    localStorage.removeItem('recipeDetailsActiveTab');
    this.setActiveTab(RecipeDetailsTabsEnum.info);
  }

  private getIsTabVisible(
    tab: RecipeDetailsTabModel,
    recipeDetails: RecipeDetailsModalParamsModel | null,
    measurementId?: string
  ): boolean {
    const isColorCombination: boolean = !!recipeDetails?.combination?.isCombination;
    const isSpectralChartOrAnglesTab: boolean = [RecipeDetailsTabsEnum.chart, RecipeDetailsTabsEnum.preview].includes(tab.id);
    const isLinkedColorTab: boolean = RecipeDetailsTabsEnum.linkedColor === tab.id;

    if (isColorCombination) {
      return false;
    }

    if (isSpectralChartOrAnglesTab) {
      return !!measurementId;
    }

    if (isLinkedColorTab) {
      return !!recipeDetails?.linkedColor?.isLinkedColor;
    }

    return true;
  }

  private getRecipeDetailsTabs(): Array<RecipeDetailsTabModel> {
    return [
      {
        id: RecipeDetailsTabsEnum.info,
        label: 'recipes.recipeDetailsModal.tabs.labels.info',
        disabled: false,
      },
      {
        id: RecipeDetailsTabsEnum.prices,
        label: 'recipes.recipeDetailsModal.tabs.labels.prices',
        disabled: false,
        permission: UserPermissionNameEnum.priceListShow,
      },
      {
        id: RecipeDetailsTabsEnum.ingredients,
        label: 'recipes.recipeDetailsModal.tabs.labels.ingredients',
        disabled: false,
      },
      {
        id: RecipeDetailsTabsEnum.chart,
        label: 'recipes.recipeDetailsModal.tabs.labels.chart',
        disabled: false,
      },
      {
        id: RecipeDetailsTabsEnum.preview,
        label: 'recipes.recipeDetailsModal.tabs.labels.preview',
        disabled: false,
      },
      {
        id: RecipeDetailsTabsEnum.linkedColor,
        label: 'recipes.recipeDetailsModal.tabs.labels.linkedColor',
        disabled: false,
      },
      {
        id: RecipeDetailsTabsEnum.colorCombinations,
        label: '',
        disabled: false,
      },
    ];
  }
}
